import React from 'react';
import partnerList from 'data/partner/partnerList';
import Section from 'components/common/Section';
import { Row, Col, Image } from 'react-bootstrap';
import SectionHeader from './SectionHeader';
import RegistrationForm from 'components/authentication/RegistrationForm';

const Partner = (props) => (
  <Col xs={3} sm="auto" className="my-1 my-sm-3 px-x1">
    <Image className="landing-cta-img" {...props} alt="Partner" />
  </Col>
);

const Partners = () => {
  return (
    <Section id="contato" className="bg-200 d-flex justify-content-center align-items-center">
      <SectionHeader
        title={"Fale conosco"}
        subtitle={"Este formulário enviará seus dados para a equipe comercial"}
      />
      <Row className="justify-content-center mt-4">
        <Col xs={12} sm={10} md={8}>
          <RegistrationForm />
        </Col>
      </Row>
    </Section>
  );
};

export default Partners;
