import React, { useState } from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';
import { faqs as faqsData } from 'data/faqs';
import PricingDefaultCard from '../../../components/pages/pricing/pricing-default/PricingDefaultCard';
import useFakeFetch from 'hooks/useFakeFetch';
import { pricingData, pricingAltData } from '../../../data/pricing';
import FaqBasicCard from 'components/pages/faq/faq-basic/FaqBasicCard';
import PageHeader from 'components/common/PageHeader';
import { Link } from 'react-router-dom';
import { Card, Col, Form, FormCheck, Row, Spinner } from 'react-bootstrap';

const Processes = () => {
  const [faqs] = useState(faqsData);
  const [isMonthly, setIsMonthly] = useState(true);
  const { loading: priceLoading, data: pricing } = useFakeFetch(
    isMonthly ? pricingData : pricingAltData,
    1000
  );

  const handleToggle = () => {
    setIsMonthly(prevState => !prevState);
  };

  return (
    <>
      <Section className='border-4'>
        <SectionHeader
          id="precos"
          title="Planos flexíveis"
          subtitle="Oferecemos planos flexíveis e preços personalizados que se ajustam perfeitamente à sua necessidade de usuários, garantindo que você pague somente pelo que realmente utiliza"
        />
        <Row className="justify-content-end align-items-center mt-5 mb-2 me-9">
          <Col xs="auto">
            <FormCheck.Label htmlFor="custom-switch" className="me-3 mb-0">
              Anual
            </FormCheck.Label>
            <Form.Check type="switch" className="mb-0 d-inline-block">
              <Form.Check.Input
                type="checkbox"
                className="pe-0 falcon-form-check-input"
                checked={isMonthly}
                onChange={handleToggle}
              />
            </Form.Check>
            <Form.Check.Label className="ms-2 mb-0">Mensal</Form.Check.Label>
          </Col>
        </Row>
        <Row className="mt-4 g-0 justify-content-center">
          {priceLoading ? (
            <Col xs={12} className="py-4">
              <Spinner
                className=""
                animation="grow"
              />
            </Col>
          ) : (
            pricing.map(pricing => (
              <PricingDefaultCard key={pricing.id} pricing={pricing} />
            ))
          )}
        </Row>
      </Section>
    </>
  );
};

export default Processes;
