const { Accordion, Col, Card, Modal } = require("react-bootstrap");
const { default: AccordionBody } = require("react-bootstrap/esm/AccordionBody");
import { Link } from "react-router-dom";
import genericImage from "../../../assets/img/generic/1.jpg";
import Img01 from "../../../assets/img/integration-modals/01.png";
import { useState } from "react";

export function Item01Accordion({ activeKeys, toggleAccordionItem }) {

    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setModalShow(true);
    };

    const handleCloseModal = () => {
        setModalShow(false);
    };

    return (
        <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => toggleAccordionItem("0")}>
                Habilitar integração com o Datto RMM
            </Accordion.Header>
            <AccordionBody>
                <Col>
                    <p>Complete os seguintes passos no Datto RMM:</p>
                    <ol>
                        <li className="mb-2">Navegue até <strong>Setup &gt; Integrations &gt; MSP Desk.</strong></li>
                        <li className="mb-2">Na aba <strong>Credenciais de API</strong>, clique em <strong>habilitar integração.</strong></li>
                        <li style={{ listStyle: 'none' }}>
                            <img
                                className="mb-2 ms-2"
                                src={Img01}
                                alt="Descrição da Imagem"
                                style={{
                                    cursor: 'pointer',
                                    width: '500px',
                                    height: '70px',
                                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;',
                                    objectFit: 'contain'
                                }}
                                onClick={() => handleImageClick(Img01)}
                            />
                        </li>
                        <li className="mb-2" value={3}>
                            Insira seu <strong>Nome de usuário (chave)</strong> e <strong>Senha (segredo)</strong>.
                            <Card className="mb-2 col-xl-12 ms-2" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                                <Card.Body>
                                    <Card.Title className="text-danger">Importante</Card.Title>
                                    <Card.Text>
                                        Você deve ter uma conta de nível administrador dentro do MSP Desk para evitar erros de permissões.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </li>
                        <li className="mb-2" value={4}>Selecione quais tipos de conta do MSP Desk você gostaria de mapear para o Datto RMM.</li>
                        <img
                            className="mb-2 ms-2"
                            src={genericImage}
                            alt="Descrição da Imagem"
                            style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                            onClick={() => handleImageClick(genericImage)}
                        />
                        <Card className="ms-2 mb-2 col-xl-12" style={{ margin: '10px 0', borderLeft: '5px solid blue' }}>
                            <Card.Body /* style={{ maxHeight: '80px' }} */>
                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                <Card.Text>
                                    Você deve selecionar pelo menos uma conta para salvar essa configuração.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <li className="mb-2" value={5}>Clique em <strong>Salvar e checar agora</strong>.</li>
                        <img
                            className="mb-2 ms-2"
                            src={genericImage}
                            alt="Descrição da Imagem"
                            style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                            onClick={() => handleImageClick(genericImage)}
                        />
                        <Card className="ms-2 mb-3 col-xl-12" style={{ margin: '10px 0', borderLeft: '5px solid blue' }}>
                            <Card.Body className="mb-2" style={{ maxHeight: '105px' }}>
                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                <Card.Text>
                                    Quando clicar em <strong>Salvar e checar agora, </strong>
                                    as outras abas na integração serão desabilitadas temporariamente enquanto os dados do MSP Desk são carregados.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <li className="mb-3" value={6}>Com o sucesso da conexão, o botão de autenticação será desabilitado e mostrará
                            <strong> Salvo e autenticado. </strong> Contanto que a a integração não seja desligada, os detalhes de autenticação
                            serão salvos e a integração se manterá conectada por cada conta de usuário.</li>
                        <img
                            className="mb-3 ms-2"
                            src={genericImage}
                            alt="Descrição da Imagem"
                            style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                            onClick={() => handleImageClick(genericImage)}
                        />
                        <li className="mb-2" value={7}>Para saber mais sobre o switch de <strong>Single Sign-On para Usuários, </strong>veja a próxima
                            seção, <Link to={'#'}> Configurar SSO</Link>.</li>
                    </ol>
                </Col>
                {/* Modal com a imagem maior */}
                <Modal
                    show={modalShow}
                    onHide={handleCloseModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onClick={handleCloseModal} // Fecha o modal ao clicar fora
                >
                    <Modal.Body onClick={(e) => e.stopPropagation()}>
                        <img
                            src={selectedImage} // Usa a imagem selecionada
                            alt="Imagem Ampliada"
                            style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </Modal.Body>
                </Modal>
            </AccordionBody>
        </Accordion.Item>
    )
}
