import { Accordion, Card, Modal, Table } from "react-bootstrap";
import genericImage from "../../../assets/img/generic/1.jpg";
import { useAppContext } from "providers/AppProvider";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useState } from "react";

export default function Item04Accordion({ activeKeys, toggleAccordionItem }) {

    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const {
        config: { isDark }
    } = useAppContext();

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setModalShow(true);
    };

    const handleCloseModal = () => {
        setModalShow(false);
    };

    return (
        <Accordion.Item eventKey="3">
            <Accordion.Header onClick={() => toggleAccordionItem("3")}>
                Configurar o Mapeamento de Prioridade de Alertas
            </Accordion.Header>
            <AccordionBody>
                <p className="mb-2">
                    As regras de alerta permitem que você gerencie os alertas do Datto RMM e seus respectivos tickets de alerta
                    no MSP Desk. No cartão de configuração da integração, comece configurando o mapeamento de prioridade de
                    alerta na seção <strong> Mapeamento de Alerta</strong> da aba <strong> Alertas</strong>.
                </p>
                <img
                    className="mb-3 ms-2"
                    src={genericImage}
                    alt="Descrição da Imagem"
                    style={{ cursor: 'pointer', width: '10vw', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                    onClick={() => handleImageClick(genericImage)}
                />
                <p className="mb-3">
                    Você pode ativar ou desativar a alternância <strong> Habilitar Sincronização de Tickets</strong> a qualquer momento para controlar se a
                    sincronização de tickets está ativa ou não. Sua configuração selecionada será salva e persistirá na próxima vez que a página
                    for acessada, sem a necessidade de clicar no botão <strong> Salvar</strong>.
                </p>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '7px', paddingBottom: '7px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Você pode desejar manter essa alternância desativada enquanto trabalha na sua configuração e salva suas alterações. Depois,
                            você pode ativar a alternância sempre que estiver pronto para iniciar oficialmente a sincronização de tickets com suas
                            atualizações.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <p className="mb-2">
                    Você pode selecionar os valores padrão para a prioridade do ticket que será mapeada do Datto RMM para o MSP Desk, da seguinte forma:
                </p>
                <Table className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`} striped bordered hover>
                    <thead>
                        <tr>
                            <th>Campo</th>
                            <th>Descrição</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Prioridade do Monitor RMM</td>
                            <td>
                                Exibe as prioridades dos alertas que são gerados no Datto RMM. Consulte Alertas. As prioridades são atribuídas por meio de monitores. Consulte Monitores.
                            </td>
                        </tr>
                        <tr>
                            <td>Prioridade do MSP Desk</td>
                            <td>
                                Selecione uma prioridade de ticket do MSP Desk no menu suspenso para mapeá-la à prioridade de alerta correspondente do
                                Datto RMM. Você também pode buscar uma prioridade específica usando a barra de pesquisa.
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p className="mb-3">
                    Para salvar este mapeamento, clique em Salvar no canto superior direito ou inferior direito da página.
                </p>
                <p className="mb-3">
                    Em seguida, você configurará as configurações de tickets nesta aba. Consulte a próxima seção.
                </p>
            </AccordionBody>
            {/* Modal com a imagem maior */}
            <Modal
                show={modalShow}
                onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onClick={handleCloseModal} // Fecha o modal ao clicar fora
            >
                <Modal.Body onClick={(e) => e.stopPropagation()}> {/* Impede fechamento ao clicar na imagem */}
                    <img
                        src={selectedImage} // Usa a imagem selecionada
                        alt="Imagem Ampliada"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Modal.Body>
            </Modal>
        </Accordion.Item>
    )
}