import { Accordion, Card, Modal, Table } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import genericImage from "../../../assets/img/generic/1.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useAppContext } from "providers/AppProvider";

export default function Item05Accordion({ activeKeys, toggleAccordionItem }) {

    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const {
        config: { isDark }
    } = useAppContext();

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setModalShow(true);
    };

    const handleCloseModal = () => {
        setModalShow(false);
    };

    const [innerActiveKey02, setInnerActiveKey02] = useState(null); // Estado para o segundo accordion interno

    return (
        <Accordion.Item eventKey="4">
            <Accordion.Header onClick={() => toggleAccordionItem("4")}>
                Configurar configurações de tickets
            </Accordion.Header>
            <AccordionBody>
                <p className="mb-2">
                    No cartão de configuração da integração, após configurar o mapeamento de prioridade de alerta, você pode configurar as
                    configurações de tickets na aba <strong> Alertas</strong>.
                </p>
                <p className="mb-3">
                    Você pode ativar ou desativar a alternância <strong> Habilitar Sincronização de Tickets</strong> a qualquer momento para controlar
                    se a sincronização
                    de tickets está ativa ou não. Sua configuração selecionada será salva e persistirá na próxima vez que a página for acessada, sem a
                    necessidade de clicar no botão <strong> Salvar</strong>.
                </p>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '7px', paddingBottom: '7px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Você pode desejar manter essa alternância desativada enquanto trabalha na sua configuração e salva suas alterações.
                            Depois, você pode ativar a alternância sempre que estiver pronto para iniciar oficialmente a sincronização de tickets
                            com suas atualizações.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Accordion activeKey={innerActiveKey02} className="mb-3">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => setInnerActiveKey02(innerActiveKey02 === "0" ? null : "0")}>
                            Seção de configurações de tickets
                        </Accordion.Header>
                        <AccordionBody>
                            <img
                                className="mb-4 ms-2"
                                src={genericImage}
                                alt="Descrição da Imagem"
                                style={{ cursor: 'pointer', width: '13vw', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                                onClick={() => handleImageClick(genericImage)}
                            />
                            <Table className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`} striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Campo</th>
                                        <th>Descrição</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Fonte do Ticket</td>
                                        <td>
                                            No menu suspenso, selecione a Fonte que você gostaria de atribuir a todos os tickets de alerta criados no MSP Desk pelo Datto RMM. Consulte este tópico no sistema de Ajuda do MSP Desk: <Link>Fontes de tickets</Link>.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tipo de Nota do Ticket</td>
                                        <td>
                                            <p className="mb-2">
                                                Os usuários do Datto RMM podem adicionar manualmente notas aos tickets do MSP Desk criados via Datto RMM. No menu suspenso, selecione o tipo de nota padrão a ser usado para notas criadas pelo usuário.
                                            </p>
                                            <Card className="mb-2 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                                <Card.Body style={{ maxHeight: '30vh', paddingTop: '5px', paddingBottom: '5px' }}>
                                                    <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                                    <Card.Text>
                                                        Notas criadas automaticamente pelo Datto RMM usarão o tipo de nota do sistema <strong> RMM Nota</strong>.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Publicar Notas do RMM Para</td>
                                        <td>
                                            Os usuários do Datto RMM podem adicionar manualmente notas aos tickets do MSP Desk criados via Datto RMM. No menu suspenso, selecione uma das seguintes opções para definir como a configuração de publicação padrão para notas criadas pelo usuário:
                                            <ul>
                                                <li><strong> Todos os Usuários do MSP Desk</strong>: Todos os usuários do MSP Desk.</li>
                                                <li><strong> Interno & Co-Gerido</strong>: Usuários internos e recursos externos que foram configurados com acesso e visibilidade co-geridos dentro do MSP Desk. Consulte este tópico no sistema de Ajuda do MSP Desk: O Help Desk Co-gerido.</li>
                                                <li><strong> Equipe de Projeto Interna</strong>: Apenas usuários internos.</li>
                                            </ul>
                                            <Card className="mb-2 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                                <Card.Body style={{ maxHeight: '30vh', paddingTop: '5px', paddingBottom: '5px' }}>
                                                    <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                                    <Card.Text>
                                                        Notas criadas automaticamente pelo Datto RMM serão publicadas apenas para usuários internos.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Status de auto-resolução para um ticket que tem o status de “Novo” (por exemplo, nenhum técnico olhou para o ticket ainda)</td>
                                        <td>
                                            Ative o toggle para que os tickets de alerta do MSP Desk com o status de Novo sejam automaticamente resolvidos quando o alerta correspondente do Datto RMM for resolvido.
                                            No menu suspenso, selecione o status padrão que você gostaria de atribuir ao ticket quando for automaticamente resolvido. O menu suspenso contém todos os status ativos conforme configurado no MSP Desk.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Status de auto-resolução para um ticket que não tem o status de “Novo” (por exemplo, um técnico já começou a trabalhar neste ticket)</td>
                                        <td>
                                            Ative o toggle para que os tickets de alerta do MSP Desk com qualquer status diferente de Novo sejam automaticamente resolvidos quando o alerta correspondente do Datto RMM for resolvido.
                                            No menu suspenso, selecione o status padrão que você gostaria de atribuir ao ticket quando for automaticamente resolvido. O menu suspenso contém todos os status ativos conforme configurado no MSP Desk.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Agrupar alertas em um único ticket se tiverem o mesmo problema para o mesmo dispositivo</td>
                                        <td>
                                            Ative ou desative o toggle para agrupar esses alertas em um único ticket ou optar por não agrupar esses alertas em um único ticket, respectivamente. Você mapeará os tipos de problema na seção subsequente, Configurações Padrão de Ticket.
                                            <br />
                                            Você pode definir o limite de tempo em que a agrupação ocorrerá. Insira um número no campo. No menu suspenso, selecione minutos, horas ou dias. O limite é baseado no alerta original.
                                            <strong>NOTA:</strong> O valor máximo em cada um dos campos é 60 dias / 99 horas / 999 minutos. Você só pode usar inteiros.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Agrupar alertas em um único ticket se tiverem um problema diferente para o mesmo dispositivo</td>
                                        <td>
                                            Ative ou desative o toggle para agrupar esses alertas em um único ticket ou optar por não agrupar esses alertas em um único ticket, respectivamente. Você mapeará os tipos de problema na seção subsequente, Configurações Padrão de Ticket.
                                            <br />
                                            Você pode definir o limite de tempo em que a agrupação ocorrerá. Insira um número no campo. No menu suspenso, selecione minutos, horas ou dias. O limite é baseado no alerta original.
                                            <strong>NOTA:</strong> O valor máximo em cada um dos campos é 60 dias / 99 horas / 999 minutos. Você só pode usar inteiros.
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </AccordionBody>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => setInnerActiveKey02(innerActiveKey02 === "1" ? null : "1")}>
                            Seção de Configurações Padrão de Tickets
                        </Accordion.Header>
                        <AccordionBody>
                            <img
                                className="mb-4 ms-2"
                                src={genericImage}
                                alt="Descrição da Imagem"
                                style={{ cursor: 'pointer', width: '6vw', height: '60px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                                onClick={() => handleImageClick(genericImage)}
                            />
                            <p>
                                A coluna <strong> Tipo de Monitor</strong> exibe todos os tipos de monitor no Datto RMM e uma opção para configuração
                                de tickets do Agente do cliente
                                final (ou seja, tickets criados a partir de um dispositivo via o Agente instalado).
                                A linha para tickets do Agente do cliente final é chamada <strong> Agente do Cliente Final</strong>.
                            </p>
                            <p>
                                Para cada tipo de monitor e a linha do <strong> Agente do Cliente Final</strong>, você pode definir os valores
                                padrão nos tickets de alerta do MSP Desk
                                criados a partir dos alertas desse tipo de monitor e dos tickets do Agente do cliente final, respectivamente:
                            </p>
                            <Table className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`} striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Configuração no Datto RMM</th>
                                        <th>Resultado no MSP Desk</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>
                                                Coluna de <strong> Fila</strong>.
                                            </p>
                                            <Card className="mb-2 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                                <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                                    <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                                    <Card.Text>
                                                        Esta é a única coluna que requer valores a serem definidos.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </td>
                                        <td>Define os valores padrão para o campo <strong> Fila</strong> em tickets do MSP Desk.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Coluna de <strong> Problemas</strong>.
                                        </td>
                                        <td>Define os valores padrão para o campo <strong> Tipo de Problema</strong> em tickets do MSP Desk.</td>
                                    </tr>
                                    <tr>
                                        <td>Coluna de <strong> Subproblemas</strong>.
                                            <Card className="mb-2 col-xl-12 mt-2" style={{ borderLeft: '5px solid red' }}>
                                                <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                                    <Card.Title style={{ color: 'red' }}>IMPORTANTE</Card.Title>
                                                    <Card.Text>
                                                        Se você escolher selecionar um tipo de problema, também deve selecionar um tipo de subproblema.
                                                        Caso contrário, os tickets serão criados sem nenhum.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </td>
                                        <td>Define os valores padrão para o campo <strong> Tipo de Subproblema</strong> em tickets do MSP Desk.</td>
                                    </tr>
                                    <tr>
                                        <td>Coluna de <strong> Tipo de Trabalho</strong>.</td>
                                        <td>Define os valores padrão para o campo <strong> Tipo de Trabalho</strong> em tickets do MSP Desk.</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p>
                                Os menus suspensos listam todos os valores ativos conforme configurado no MSP Desk. Use os menus suspensos para fazer seleções ou
                                insira valores nas barras de pesquisa para selecionar facilmente os resultados específicos que você está procurando. Você
                                pode usar os mesmos métodos na linha <strong> Aplicar a Todas as Linhas</strong>
                                no topo da lista para definir a mesma seleção em todas as linhas correspondentes.
                            </p>
                            <p>
                                Para salvar essas configurações, clique em <strong> Salvar</strong> no canto superior direito ou inferior direito da página.
                            </p>
                        </AccordionBody>
                    </Accordion.Item>
                </Accordion>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '7px', paddingBottom: '7px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Recomendamos que você revise suas configurações de tickets após cada lançamento do Datto RMM para garantir que seus tipos de
                            monitoramento estejam devidamente configurados.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '7px', paddingBottom: '7px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Caso um novo tipo de monitoramento seja introduzido no Datto RMM e você ainda não tenha reconfigurado a integração de tickets para
                            esse tipo de monitoramento, os tickets criados através desse monitoramento serão atribuídos à fila de
                            <strong> Alertas de Monitoramento</strong> no
                            MSP Desk por padrão.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Por padrão, os tickets criados a partir de alertas não são atribuídos a nenhum recurso no MSP Desk.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '15px', paddingBottom: '15px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Se um monitor estiver configurado para criar um ticket quando um alerta for gerado, mas o dispositivo não puder ser sincronizado
                            com o
                            MSP Desk (por exemplo, o contrato ou contato do item de configuração do MSP Desk está inativo, a sincronização do dispositivo está
                            desativada, etc.), o Datto RMM colocará o evento de sincronização do dispositivo em fila e tentará criar um ticket três vezes
                            (cada vez que uma sincronização de dispositivo for acionada, aproximadamente uma vez por minuto). Se o dispositivo não conseguir
                            sincronizar dentro desse período, um ticket ainda será criado para a organização correspondente no MSP Desk contendo o nome e o ID
                            do dispositivo e um link para o dispositivo no Datto RMM. Caso o site do Datto RMM não esteja mais mapeado para uma organização do
                            MSP Desk, um ticket será criado para a conta zero
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Se um item de configuração do MSP Desk sincronizado com um dispositivo Datto RMM tiver um contato atribuído, mas esse contato
                            estiver inativo, a criação do ticket falhará.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            Se a criação do ticket falhar (por exemplo, devido a alterações feitas em credenciais, filas, categorias, configurações de
                            segurança,
                            etc.), um e-mail de notificação será enviado para os endereços de e-mail configurados para receber alertas. Se nenhum endereço de
                            e-mail listado em <strong> Configuração &gt; Configurações Globais &gt; Destinatários de E-mail</strong>
                            tiver <strong> Alertas</strong> selecionados, o e-mail de falha do
                            ticket será enviado para o endereço de e-mail configurado em
                            <strong> Configuração &gt; Configurações da Conta &gt; E-mail da Conta</strong>. Consulte
                            <Link> Destinatários de E-mail</Link> em <Link> Configurações Globais</Link>. O e-mail de notificação especificará a
                            mensagem de erro e o motivo do erro.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </AccordionBody>
            {/* Modal com a imagem maior */}
            <Modal
                show={modalShow}
                onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onClick={handleCloseModal} // Fecha o modal ao clicar fora
            >
                <Modal.Body onClick={(e) => e.stopPropagation()}> {/* Impede fechamento ao clicar na imagem */}
                    <img
                        src={selectedImage} // Usa a imagem selecionada
                        alt="Imagem Ampliada"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Modal.Body>
            </Modal>
        </Accordion.Item>
    )
}