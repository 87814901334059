import Section from "components/common/Section";
import SectionHeader from "../landing/SectionHeader";
import { useAppContext } from "providers/AppProvider";
import { Accordion, Card, Col, Modal, Row, Table } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import genericImage from "../../../assets/img/generic/1.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Item01Accordion } from "./accordion-item-01";
import { Item02Accordion } from "./accordion-item-02";
import Item03Accordion from "./accordion-item-03";
import Item04Accordion from "./accordion-item-04";
import Item05Accordion from "./accordion-item-05";

const IntegrationTest = () => {

    const [activeKeys, setActiveKeys] = useState([]);

    const {
        config: { isDark }
    } = useAppContext();

    const toggleAccordionItem = (key) => {
        setActiveKeys(prevKeys =>
            prevKeys.includes(key)
                ? prevKeys.filter(k => k !== key) // Remove a chave se já estiver aberta
                : [...prevKeys, key] // Adiciona a chave se não estiver aberta
        );
    };

    return (
        <Section
            id="inicio"
            className={`${isDark ? "bg-dark" : "bg-light"} py-0 overflow-hidden mr-1`}
            data-bs-theme={isDark ? "dark" : "light"}
            position="center bottom"
            overlay
            style={{ minHeight: '100vh', overflowY: 'auto' }} // Garante que a rolagem vertical esteja visível
        >
            <Row className="justify-content-center align-items-center pt-8 pb-lg-4 pb-xl-0">
                <Col md={12} lg={12} xl={12} className="pb-4 pb-xl-2 text-center">
                    <SectionHeader
                        title={'Integração com o Datto RMM'}
                    /* subtitle={'Documentação da integração'} */
                    />
                </Col>
                <Col>
                    <p className="fs-6 mb-3">
                        Permissões
                    </p>
                    <Card className="mb-2 col-xl-12 ms-3" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                        <Card.Body style={{ padding: '10px' }}>
                            <Card.Title className="text-danger">Importante</Card.Title>
                            <Card.Text>
                                Você deve ter um nível de segurança padrão de Administrador no Datto RMM ao configurar a
                                integração. Se você tiver mais de um nível de segurança, certifique-se de que Administrador
                                esteja selecionado como seu nível de segurança padrão.
                                Para mais informações, consulte <Link>Criando um usuário</Link>.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2 col-xl-12 ms-3" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                        <Card.Body style={{ padding: '13px' }}>
                            <Card.Title className="text-danger">Importante</Card.Title>
                            <Card.Text>
                                Você deve usar uma conta de sincronização do MSP Desk ao executar o assistente de configuração da integração,
                                e essa conta de usuário do MSP Desk deve ter um nível de
                                segurança de <strong> Usuário de API</strong>, e o <strong> Datto RMM</strong> deve ser
                                selecionado como o <strong> Fornecedor de Integração</strong>. Consulte este tópico no sistema de ajuda do MSP Desk:
                                <Link> Adicionando ou editando um usuário de API</Link>. Certifique-se de que nas configurações de nível de segurança,
                                a caixa de seleção <strong> Não é necessário alterar a senha (a senha não expira)</strong> esteja marcada.
                                Consulte este tópico no sistema de ajuda do MSP Desk: <Link> Outras configurações de segurança</Link>.
                                Também certifique-se de que o domínio do usuário de API corresponda ao domínio principal do seu
                                banco de dados do MSP Desk.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2 col-xl-12 ms-3" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                        <Card.Body style={{ padding: '13px' }}>
                            <Card.Title className="text-danger">Importante</Card.Title>
                            <Card.Text>
                                No MSP Desk, certifique-se de que todas as <strong> Permissões de Dados Protegidos</strong>
                                estejam habilitadas para a conta de sincronização.
                                Você pode fazer isso no MSP Desk navegando para o menu
                                <strong> MSP Desk PSA &gt; Admin &gt; Configurações da Empresa & Usuários &gt; Recursos/Usuários
                                    (RH) &gt; Segurança &gt; Permissão de Dados Protegidos</strong>.
                                Para mais informações, consulte este tópico no sistema de ajuda do MSP Desk: <Link> Visualizando dados protegidos</Link>.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <p className="fs-6 mb-3">
                        Sobre a integração
                    </p>
                    <p>
                        A integração avançada do MSP Desk e Datto RMM introduz uma plataforma de gestão empresarial verdadeiramente
                        unificada, apresentando o seguinte:
                    </p>
                    <ul>
                        <li>
                            <p>
                                As organizações do MSP Desk podem ser mapeadas para um ou vários sites do Datto RMM através de qualquer uma das plataformas.
                                Para mais informações, consulte este tópico no sistema de ajuda do MSP Desk: Navegando da organização do
                                MSP Desk para o site do RMM.
                            </p>
                        </li>
                        <li>
                            <p>
                                A funcionalidade de Sistema Único de Registro (SSoR) fornece acesso direto de tickets do Datto RMM para o MSP Desk
                                e vice-versa, com informações de tickets mais detalhadas. Com a integração ativada, a entidade do ticket reside
                                apenas no MSP Desks.
                            </p>
                        </li>
                        <li>
                            <p>
                                Cada tipo de monitor e os tickets do Agente do cliente final podem ser configurados para criar tickets no MSP Desk
                                em filas designadas com padrões configuráveis de problema, sub-problema, tipo de trabalho e nota do ticket.
                                Os padrões podem ser substituídos para monitores específicos.
                            </p>
                        </li>
                        <li>
                            <p>
                                Os tipos de dispositivos do Datto RMM podem ser mapeados para produtos e tipos de itens de configuração do MSP Desk.
                            </p>
                        </li>
                        <li>
                            <p>
                                Os campos definidos pelo usuário (UDFs) dos dispositivos do Datto RMM podem ser mapeados para os UDFs dos itens de
                                configuração do MSP Desk.
                            </p>
                        </li>
                        <li>
                            <p>
                                Os alertas do Datto RMM podem ser fechados automaticamente se o ticket de alerta do MSP Desk for concluído usando
                                uma regra de fluxo de trabalho do MSP Desk e uma chamada de extensão. As regras de fluxo de trabalho no
                                MSP Desk realizam automaticamente certas atualizações e ações quando eventos específicos
                                acontecem e condições são atendidas. Você tem vários atributos específicos do Datto RMM que estão disponíveis
                                para uso nas regras de fluxo de trabalho do MSP Desk. Para aprender como adicionar e editar regras de fluxo
                                de trabalho, consulte os tópicos de regras de fluxo de trabalho no sistema de ajuda do MSP Desk:
                                Regras de fluxo de trabalho do MSP Desk.
                            </p>
                        </li>
                        <li>
                            <p>
                                A funcionalidade de login único (SSO) permite que você seja automaticamente logado no Datto RMM se já estiver
                                logado no MSP Desk e clicar em um link habilitado para SSO dentro do MSP Desk.
                            </p>
                        </li>
                        <li>
                            <p>
                                Você pode simplificar seu fluxo de trabalho fazendo com que seu ticket de alerta do MSP Desk seja automaticamente
                                resolvido quando o alerta correspondente do Datto RMM for resolvido. Você também pode especificar quando um
                                alerta do Datto RMM deve ser considerado repetitivo ou relacionado a outro alerta.
                            </p>
                        </li>
                        <li>
                            <p>
                                Você pode usar atributos específicos do Datto RMM nas regras de fluxo de trabalho do MSP Desk.
                            </p>
                        </li>
                        <li>
                            <p>
                                As abas de painel de alertas e dispositivos do Datto RMM estão disponíveis no MSP Desk. Os painéis são configurados
                                com base nos dados adicionais do dispositivo que são sincronizados entre o Datto RMM e o MSP Desk.
                            </p>
                        </li>
                        <li>
                            <p>
                                Os dispositivos do Datto RMM podem ser mapeados para contratos de faturamento no MSP Desk. Os dispositivos do
                                Datto RMM aparecem no MSP Desk em tempo real sem um processo manual de descoberta de dispositivos.
                                Na página de Mapeamento de Itens de Configuração de Gerenciamento de Endpoint no MSP Desk, você pode
                                atribuir um contato, um contrato de faturamento e, para contratos de serviço recorrentes, um serviço
                                ou pacote ao dispositivo. Você também pode definir o dispositivo como <strong> Revisado para Contrato </strong>
                                (o que o remove da página). Para mais informações, consulte este tópico no sistema de ajuda do
                                MSP Desk: Mapeando dispositivos descobertos para um contato e contrato.
                            </p>
                        </li>
                        <li>
                            <p>
                                Você pode acessar várias ferramentas do Datto RMM diretamente do MSP Desk usando menus de clique direito e de contexto.
                            </p>
                        </li>
                        <li>
                            <p>
                                Você pode acessar e gerenciar tickets do MSP Desk dentro de uma sessão de Web Remote. Consulte o
                                companheiro do MSP Desk no Web Remote.
                            </p>
                        </li>
                    </ul>
                    <Card className="mb-2 col-xl-12 ms-3" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                        <Card.Body style={{ padding: '10px' }}>
                            <Card.Title className="text-danger">Importante</Card.Title>
                            <Card.Text>
                                Recomendamos que apenas uma conta do Datto RMM seja sincronizada com um banco de dados do MSP Desk.
                                Conectar uma conta adicional do Datto RMM ao mesmo banco de dados do MSP Desk removerá o link
                                original, e a integração existente não funcionará mais.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2 col-xl-12 ms-3" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                        <Card.Body style={{ padding: '10px' }}>
                            <Card.Title className="text-danger">Importante</Card.Title>
                            <Card.Text>
                                Se você planeja mudar o domínio do seu banco de dados do MSP Desk, certifique-se de que esta
                                integração esteja desativada primeiro. Você pode reativar a integração após a mudança de
                                domínio ser concluída. Consulte Desativar a Integração do MSP Desk.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <p className="fs-6 mb-3 mt-3">
                        Como ...
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-center mb-5">
                <Col md={12} lg={12} xl={12}>
                    <Accordion activeKey={activeKeys} className="col-lg-12" /* style={{ paddingRight: '15px' }} */>
                        <Item01Accordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
                        <Item02Accordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
                        <Item03Accordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
                        <Item04Accordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
                        <Item05Accordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
                    </Accordion>
                </Col>
            </Row>
        </Section>

    );
};

export default IntegrationTest;
