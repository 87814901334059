import logoLight from '../assets/img/logo-light.svg';
import logoDark from '../assets/img/logo-dark.svg';

const handleNavbarTransparency = (isDark) => {
  const navbar = document.querySelector('.navbar-standard');
  const windowHeight = window.innerHeight;

  if (navbar) {
    const scrollTop = window.scrollY;
    let alpha = (scrollTop / windowHeight) * 2;
    alpha >= 1 && (alpha = 1);
    navbar.style.backgroundColor = `rgba(11, 23, 39, ${alpha})`;

    const isScrolled = window.scrollY > 10;
    const logos = navbar.querySelectorAll('.logoItem img');

    logos.forEach((logo) => {
      if (isScrolled && !isDark) {
        logo.src = logoDark;
      } else if (!isScrolled && !isDark) {
        logo.src = logoLight;
      } /* else if (!isScrolled && isDark) {
        if (logo) {
          logo.src = logoLight; 
        }
      } else if (!isScrolled && !isDark) {
        if (logo) {
          logo.src = logoDark; 
        }
      } */
    })
  }
};

export default handleNavbarTransparency;
