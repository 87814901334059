import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';

const RegistrationForm = ({ hasLabel }) => {
  const form = useRef();

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    user_company: '',
    user_position: '',
    user_operators: '',
    user_message: ''
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          toast.success('Informações enviadas com sucesso!', {
            theme: 'colored'
          });
          console.log('Email enviado com sucesso!', result.text);
        },
        (error) => {
          toast.error('Falha no envio das informações...', {
            theme: 'colored'
          });
          console.error('Erro ao enviar o e-mail:', error.text);
        }
      );

    setFormData({
      user_name: '',
      user_email: '',
      user_phone: '',
      user_company: '',
      user_position: '',
      user_operators: '',
      user_message: ''
    });
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form ref={form} onSubmit={sendEmail}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Nome Completo</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Nome Completo' : ''}
          value={formData.user_name}
          name="user_name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email Corporativo</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email Corporativo' : ''}
          value={formData.user_email}
          name="user_email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Telefone</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Telefone' : ''}
          value={formData.user_phone}
          name="user_phone"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Nome da Empresa</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Nome da Empresa' : ''}
          value={formData.user_company}
          name="user_company"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Cargo</Form.Label>}
        <Form.Select
          value={formData.user_position}
          name="user_position"
          onChange={handleFieldChange}
          className='text-secondary'
        >
          <option value="">Selecione seu cargo</option>
          <option value="Analista">Analista</option>
          <option value="Assessor">Assessor</option>
          <option value="Assistente">Assistente</option>
          <option value="Autônomo">Autônomo</option>
          <option value="Consultor">Consultor</option>
          <option value="Coordenador">Coordenador</option>
          <option value="Diretor">Diretor</option>
          <option value="Gerente">Gerente</option>
          <option value="Supervisor">Supervisor</option>
          <option value="Vendedor">Vendedor</option>
          <option value="Estagiário">Estagiário</option>
          <option value="Estudante">Estudante</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Quantidade de Operadores</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Quantidade de Operadores' : ''}
          value={formData.user_operators}
          name="user_operators"
          onChange={handleFieldChange}
          type="number"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Mensagem</Form.Label>}
        <Form.Control
          as="textarea"
          placeholder={!hasLabel ? 'Mensagem' : ''}
          value={formData.user_message}
          name="user_message"
          onChange={handleFieldChange}
        />
      </Form.Group>

      <Form.Group>
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.user_name ||
            !formData.user_email ||
            !formData.user_phone ||
            !formData.user_company ||
            !formData.user_position ||
            !formData.user_operators
          }
        >
          Enviar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default RegistrationForm;
