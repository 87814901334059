import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

export const bgWhiteIcons = [
  { icon: ['fab', 'facebook-f'], color: 'facebook', bg: 'white', href: 'https://www.facebook.com/MSPDeskOficial' },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', bg: 'white', href: 'https://www.linkedin.com/company/mspdeskoficial/about/' },
  { icon: faInstagram, color: 'instagram', bg: 'white', href: 'https://www.instagram.com/mspdeskoficial/?igsh=d3ZrZ3U3Z3I0cXll' },
];
export default [
  { icon: ['fab', 'facebook-f'], color: 'facebook', href: '#!' },
  { icon: ['fab', 'twitter'], color: 'twitter', href: '#!' },
  {
    icon: ['fab', 'google-plus-g'],
    color: 'google-plus',
    href: '#!'
  },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', href: '#!' },
  { icon: ['fab', 'medium-m'], color: 'gray-700', href: '#!' }
];
