
export const pricingData = [
  {
    id: 0,
    title: 'Mensal',
    subTitle: 'Uma escolha flexível e sem limite de recursos, garantindo adaptabilidade e suporte para sua empresa.',
    price: '87,9',
    subscription: 'mês /usuário',
    url: '/e-commerce/billing',
    buttonText: 'Quero uma demonstração',
    isFeatured: false,
    featureTitle: 'Descrição:',
    features: [
      { id: 0, title: '3 usuários' },
      { id: 2, title: '2 GB de dados' },
      { id: 3, title: 'Chamados ilimitados' },
    ]
  },
  /* {
    id: 1,
    title: 'Anual',
    subTitle:
      `O melhor custo benefício 5% OFF,
      economize 125.82 R$.`,
    price: 2390.5,
    subscription: 'ano',
    url: '/e-commerce/billing',
    buttonText: 'Comece grátis',
    isFeatured: true,
    featureTitle: 'Descrição:',
    features: [
      { id: 1, title: '3 usuários' },
      { id: 2, title: '2 GB de dados' },
      { id: 3, title: 'Chamados ilimitados' },
    ]
  }, */
  {
    id: 2,
    title: 'Personalizado',
    subTitle: 'Para empresas com mais de 50 usuários. Ideal para negócios que buscam eficiência, escalabilidade e customização.',

    subscription: 'mês',
    url: '/e-commerce/billing',
    buttonText: 'Consultar oferta',
    isFeatured: false,
    featureTitle: 'Descrição:',
    features: [
      { id: 0, title: '50 usuários' },
      { id: 2, title: 'armazenamento personalizado' },
      { id: 3, title: 'Chamados ilimitados' },
    ]
  }
];

export const pricingAltData = [
  {
    id: 1,
    title: 'Anual',
    subTitle:
      `Para quem busca o melhor custo benefício disponível, com 5% de desconto, economize R$ 158,22.`,
    price: '3006,18',
    subscription: 'ano',
    url: '/e-commerce/billing',
    buttonText: 'Quero uma demonstração',
    isFeatured: true,
    featureTitle: 'Descrição:',
    features: [
      { id: 0, title: '3 usuários' },
      { id: 2, title: '2 GB de dados' },
      { id: 3, title: 'Chamados ilimitados' },
    ]
  },
  {
    id: 2,
    title: 'Personalizado',
    subTitle: 'Para empresas com mais de 50 usuários. Ideal para negócios que buscam eficiência, escalabilidade e customização.',
    subscription: 'mês',
    url: '/e-commerce/billing',
    buttonText: 'Consultar oferta',
    isFeatured: false,
    featureTitle: 'Descrição:',
    features: [
      { id: 0, title: '50 usuários' },
      { id: 2, title: 'armazenamento personalizado' },
      { id: 3, title: 'Chamados ilimitados' },
    ]
  }
];
