import Cookies from "js-cookie";

const verifyPermissionsOfUser = (request) => {
  if (request.status == 403) {
    return window.location.href = '/errors/access-denied';
  } else if (request.status == 401) {
    return window.location.href = `/login`;
  }
};

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/v1`;
const http = {
  post: async (body, endpoint) => {
    const request = await fetch(BASE_URL + endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    verifyPermissionsOfUser(request);

    return await request.json();
  },

  postFile: async (formData, endpoint) => {
    const request = await fetch(BASE_URL + endpoint, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    });
    verifyPermissionsOfUser(request);

    return await request.json();
  },

  get: async endpoint => {
    const request = await fetch(BASE_URL + endpoint, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Accept': 'application/json'
      }
    });

    verifyPermissionsOfUser(request);
    return request.status == 200 ? await request.json() : request;
  },

  getInFormatText: async endpoint => {
    const request = await fetch(BASE_URL + endpoint, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Accept': '*/*'
      }
    });
    verifyPermissionsOfUser(request);

    return request.status == 200 ? await request.text() : request;
  },

  getWithAccept: async (endpoint, accept) => {
    const request = await fetch(BASE_URL + endpoint, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Accept': accept
      }
    });
    verifyPermissionsOfUser(request);

    return request;
  },

  getPdf: async endpoint => {
    const request = await fetch(BASE_URL + endpoint, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/pdf'
      }
    });
    verifyPermissionsOfUser(request);

    return request;
  },

  getPdf: async endpoint => {
    const request = await fetch(BASE_URL + endpoint, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/pdf'
      }
    });
    verifyPermissionsOfUser(request);

    return request;
  },

  put: async (body, endpoint) => {
    let request = await fetch(BASE_URL + endpoint, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    verifyPermissionsOfUser(request);

    return request.status === 204 ? request : await request.json();
  },

  putFile: async (file, endpoint) => {
    let request = await fetch(BASE_URL + endpoint, {
      method: 'PUT',
      body: file,
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    });
    verifyPermissionsOfUser(request);

    return request.status === 204 ? request : await request.json();
  },

  patch: async (body, endpoint) => {
    let request = await fetch(BASE_URL + endpoint, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    verifyPermissionsOfUser(request);

    return request.status === 204 ? request : await request.json();
  },

  putWithoutBody: async (endpoint) => {
    const request = await fetch(BASE_URL + endpoint, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Accept': 'application/json'
      }
    });
    verifyPermissionsOfUser(request);

    return request.status == 204 ? request : await request.json();
  },

  del: async endpoint => {
    const request = await fetch(BASE_URL + endpoint, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Accept': 'application/json'
      }
    });
    verifyPermissionsOfUser(request);

    return request.status == 204 ? request : await request.json();
  },

  delWithBody: async (body, endpoint) => {
    const request = await fetch(BASE_URL + endpoint, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    verifyPermissionsOfUser(request);

    return request.status == 204 ? request : await request.json();
  }
};

export default () => http;