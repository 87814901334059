import VaGrowth from 'assets/img/logos/VaGrowth.png';
import lightVaGrowth from 'assets/img/logos/VaGrowthLight.png';
import setortiLight from 'assets/img/logos/LogoSetorti-light.png';
import setortiDark from 'assets/img/logos/LogoSetorti-dark.png';

export default [
  {
    description:
      "Desde a implantação do MSP Desk, a experiência tem sido super agradável. A equipe, sempre muito atenciosa, entendeu minhas necessidades e trouxe ótimas ideias. O sistema é rápido, intuitivo e está em constante evolução. É uma ferramenta indispensável no meu dia a dia e agrega muito valor à nossa operação.",
    author: 'Renê Ferreira',
    designation: 'CEO',
    companyImage: VaGrowth,
    darkCompanyImage: lightVaGrowth,
    size: 60
  },
  {
    description:
      "A ferramenta MSP Desk superou nossas expectativas. Além de ser fácil de usar, os relatórios fornecem insights valiosos que nos ajudam a melhorar continuamente nossos serviços. A agilidade no desenvolvimento e implementação de novas funcionalidades é um grande ponto positivo.",
    author: 'Claudinei M. Oliveira',
    designation: 'CEO',
    companyImage: setortiDark,
    darkCompanyImage: setortiLight,
    size: 60
  },
  /* {
    description:
      "When I first saw Falcon, I was totally blown away by the care taken in the interface. It felt like something that I'd really want to use and something I could see being a true modern replacement to the current class of Bootstrap themes.",
    author: 'Liam Martens',
    designation: 'Designer',
    companyImage: amazon,
    size: 45
  } */
];
