import React from 'react';
import PropTypes from 'prop-types';
import createMarkup from 'helpers/createMarkup';
import Section from 'components/common/Section';
import IconGroup from 'components/common/icon/IconGroup';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bgWhiteIcons } from 'data/socialIcons';
import logoDark from '../../../assets/img/logo-dark.svg';
import menuList3 from 'data/footer/menuList3';
import menuList4 from 'data/footer/menuList4';

const FooterTitle = ({ children }) => (
    <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const handleNavigation = (to, isExternal) => {
    if (isExternal) {
        window.location.href = to;
    } else {
        const section = document.querySelector(to);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
};

const FooterList = ({ list }) => (
    <ul className="list-unstyled">
        {list.map(({ title, to, isExternal }, index) => (
            <li className="mb-1" key={index}>
                <a
                    className="link-600"
                    href={to}
                /* to={to} */
                /* onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(to, isExternal);
                }} */
                >
                    {title}
                </a>
            </li>
        ))}
    </ul>
);

FooterList.propTypes = {
    list: PropTypes.array.isRequired
};

const FooterBlogList = ({ list }) => (
    <ul className="list-unstyled">
        {list.map((blog, index) => (
            <li key={index}>
                <h5 className="fs-9 mb-0">
                    <Link className="link-600" to="#!">
                        {blog.title}
                    </Link>
                </h5>
                <p className="text-600 opacity-50">
                    {blog.date} &bull; {blog.read} read{' '}
                    {blog.star && (
                        <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
                    )}
                </p>
            </li>
        ))}
    </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterIntegration = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <Section bg="dark" className="footer-standard pt-8 pb-4" data-bs-theme="light">
                <div
                    className="position-absolute btn-back-to-top cursor-pointer bg-dark"
                    onClick={scrollToTop}
                >
                    <FontAwesomeIcon
                        icon="chevron-up"
                        transform="rotate-45"
                        className="text-600"
                    />
                </div>
                <Row>
                    <Col lg={4}>
                        <Row >
                            <Image style={{ width: '50%' }} src={logoDark} className='mb-4' />
                        </Row>
                        <p className="text-600 fs-8">
                            A missão da MSP Desk é facilitar o controle de chamados, tornando-o uma tarefa ágil, prática e descomplicada.
                        </p>
                        <IconGroup className="mt-4" icons={bgWhiteIcons} />
                    </Col>
                    <Col className="ps-lg-6 ps-xl-4">
                        <Row className="mt-5 mt-lg-0">
                            <Col xs={6} md={3}>
                                <FooterTitle>Navegue pelo site</FooterTitle>
                                <FooterList list={menuList4} />
                            </Col>
                            <Col xs={6} md={5}>
                                <FooterTitle>Casos de uso</FooterTitle>
                                <FooterList list={menuList3} />
                            </Col>
                            <Col className="mt-5 mt-md-0">
                                <FooterTitle>Localização</FooterTitle>
                                <Row>
                                    <p><FontAwesomeIcon icon='location-arrow' style={{ color: 'white' }} className="me-2" /> Av. São Carlos, 720 - Vila Dr. Laurindo, Tatuí - SP, 18271-380</p>
                                </Row>
                                <Row>
                                    <p > <FontAwesomeIcon icon="phone" style={{ color: 'white' }} className='me-2' /> (15) 99605-5486</p>
                                </Row>
                                {/* <FooterBlogList list={blogPostList} /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Section>
            <section className="bg-dark py-0 text-center fs-10" data-bs-theme="light">
                <hr className="my-0 border-600 opacity-25" />
                <div className="container py-3">
                    <Row className="justify-content-center">
                        <Col xs={12} sm="auto">
                            <p className="mb-0 text-600">
                                Todos os direitos reservados{' '}
                                <span className="d-none d-sm-inline-block">| MSP Desk</span>
                                <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
                                {/* <a
                  className="text-white opacity-85"
                  href="https://themewagon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ThemeWagon
                </a> */}
                            </p>
                        </Col>
                        {/* <Col xs={12} sm="auto">
              <p className="mb-0 text-600">v{version}</p>
            </Col> */}
                    </Row>
                </div>
            </section>
        </>
    );
};

export default FooterIntegration;
