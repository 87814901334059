import React from 'react';
import { Row, Col } from 'react-bootstrap';
import className from 'classnames';
import serviceList from 'data/feature/serviceList';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';

const Services = () => (
  <Section className="text-center bg-body-tertiary dark__bg-opacity-50" id="recursos">
    <SectionHeader
      title="Transforme sua Gestão com Eficiência e Eficácia usando o MSP Desk"
      subtitle="O MSP DESK é uma plataforma de service desk projetada para equipes de TI, otimizando a administração de tickets, a solução de problemas e a comunicação eficaz para promover maior satisfação dos usuários."
    />
    <Row className="mt-0">
      {serviceList.map((service, index) => (
        <Col
          lg={3}
          className={className({ 'mt-6 mt-lg-0': index > 0 })}
          key={index}
        >
          <CardService {...service} />
        </Col>
      ))}
    </Row>
  </Section>
);

export default Services;
