import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const TreeSelect = ({ data, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (dropdownOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [dropdownOpen]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = (titlePath, idPath) => {
    setSelectedValue(titlePath.join(' / '));
    setDropdownOpen(false);
    if (onSelect) {
      onSelect(idPath);
    }
  };

  const toggleExpand = (value) => {
    setExpandedKeys((prev) =>
      prev.includes(value) ? prev.filter((key) => key !== value) : [...prev, value]
    );
  };

  const getFilteredNodes = (nodes, parentKey = '') => {
    return nodes.reduce((acc, node) => {
      const nodePath = parentKey ? `${parentKey}-${node.value}` : node.value;
      if (node.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        acc.push(node);
        return acc;
      }
      if (node.children) {
        const filteredChildren = getFilteredNodes(node.children, nodePath);
        if (filteredChildren.length > 0) {
          acc.push({
            ...node,
            children: filteredChildren,
          });
        }
      }
      return acc;
    }, []);
  };

  const filteredData = searchTerm ? getFilteredNodes(data) : data;

  const renderTreeNodes = (nodes, parentKey = '', parentTitles = [], parentIds = []) => {
    return nodes.map((node) => {
      const key = parentKey ? `${parentKey}-${node.value}` : node.value;
      const isExpanded = expandedKeys.includes(key) || searchTerm.length > 0;
      const titlesPath = [...parentTitles, node.title];
      const idsPath = [...parentIds, node.value];
      return (
        <div key={key} style={{ paddingLeft: parentKey ? '20px' : '0px' }}>
          <div
            onClick={() => toggleExpand(key)}
            role='button'
            className='d-flex align-items-center dropdown-item'
          >
            {node.children ? (
              isExpanded ? <FaChevronDown className='me-1' /> : <FaChevronRight className='me-1' />
            ) : (
              <span className='me-3' />
            )}
            <span onClick={(e) => { e.stopPropagation(); node.children ? toggleExpand(key) : handleSelect(titlesPath, idsPath); }}>
              {node.title}
            </span>
          </div>
          {isExpanded && node.children && (
            <div>{renderTreeNodes(node.children, key, titlesPath, idsPath)}</div>
          )}
        </div>
      );
    });
  };

  return (
    <div ref={dropdownRef}>
      <Form>
        <InputGroup>
          <Form.Control
            type='text'
            size='sm'
            value={selectedValue || 'Selecione uma opção'}
            onClick={() => setDropdownOpen(!dropdownOpen)}
            readOnly />
          {dropdownOpen && (
            <div className="w-100 p-3 dropdown-menu show">
              <Form.Control
                type="search"
                placeholder="Buscar"
                value={searchTerm}
                onChange={handleSearchChange}
                onClick={(e) => e.stopPropagation()}
                className='mb-2'
                ref={searchInputRef}
              />
              <div>{renderTreeNodes(filteredData)}</div>
            </div>
          )}
        </InputGroup>
      </Form>
    </div>
  );
};

export default TreeSelect;
