import React from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import bg2 from 'assets/img/generic/bg-2.jpg';
import Section from 'components/common/Section';
import { useAppContext } from 'providers/AppProvider';

const Cta = () => {
  const {
    config: { isDark, isRTL }
  } = useAppContext();

  return (
    <Section
      overlay
      /* image={bg2} */
      position="center top"
      className={isDark ? "bg-dark" : "bg-light"}
      data-bs-theme={isDark ? "dark" : "light"}
    >
      <Row className="justify-content-center text-center">
        <Col lg={8}>
          <p className={`fs-6 fs-sm-3 ${isDark ? "text-light" : "text-dark"}`}>
            Descubra como podemos aumentar sua eficiência e maximizar resultados.
          </p>
        </Col>
        <Col lg={12}>
          <p className="fs-7 fs-sm-7 text-#9DA9BB">
            Automatize suas tarefas, acelere o fluxo de trabalho e aprimore a colaboração para focar no essencial.
            Descubra uma gestão eficiente e conquiste resultados de destaque!
          </p>
        </Col>
        <Col lg={12}>
          <Accordion>
            <Accordion.Item eventKey="0" className="mb-2">
              <Accordion.Header>Análise de Desempenho</Accordion.Header>
              <Accordion.Body>
                A funcionalidade de relatórios e análises do MSP Desk possibilita aos gestores monitorar o desempenho da equipe detalhadamente,
                identificando oportunidades de aprimoramento.
                Essas ferramentas permitem ajustes ágeis e eficazes para alcançar os objetivos de produtividade estabelecidos.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="mb-2">
              <Accordion.Header>Flexibilidade de Escalonamento</Accordion.Header>
              <Accordion.Body>
                Com o MSP Desk, as empresas podem ajustar suas operações conforme a demanda,
                escalonando rapidamente recursos adicionais quando necessário e mantendo a flexibilidade para adaptar as operações
                às mudanças nas necessidades do negócio.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="mb-2">
              <Accordion.Header>Eficiência na Gestão de Tempo</Accordion.Header>
              <Accordion.Body>
                As empresas podem maximizar a produtividade de seus colaboradores,
                garantindo que estejam concentrados nas tarefas mais críticas e de maior prioridade.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="mb-2">
              <Accordion.Header>Comunicação Mais Eficiente</Accordion.Header>
              <Accordion.Body>
                Explore a comunicação facilitada pelo MSP Desk, promovendo uma troca ágil de informações entre equipes internas e externas.
                Atualizações em tempo real sobre o status dos chamados reduzem atrasos e mal-entendidos,
                aprimorando significativamente a eficiência operacional.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="mb-2">
              <Accordion.Header>Resolução Mais Rápida de Problemas</Accordion.Header>
              <Accordion.Body>
                Com acesso imediato a informações cruciais e um registro detalhado de interações passadas com clientes,
                os técnicos podem resolver incidentes com maior eficiência,
                reduzindo o tempo médio de resposta e melhorando a satisfação do cliente.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="mb-2">
              <Accordion.Header>Otimização de Recursos</Accordion.Header>
              <Accordion.Body>
                O MSP Desk permite que empresas reduzam períodos de inatividade e impulsionem a produtividade geral,
                maximizando o uso de sua mão de obra e equipamentos.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className="mb-2">
              <Accordion.Header>Garantia de Consistência e Qualidade</Accordion.Header>
              <Accordion.Body>
                O MSP Desk garante que todas as etapas do serviço sejam seguidas de maneira consistente, minimizando erros e retrabalho.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8" className="">
              <Accordion.Header>Ampliação da Capacidade de Atendimento</Accordion.Header>
              <Accordion.Body>
                O MSP Desk simplifica e acelera os processos de serviço,
                capacitando as empresas a lidar com um volume maior de solicitações sem comprometer a qualidade do atendimento.
                Isso otimiza o uso dos recursos disponíveis e aprimora a experiência do cliente de forma significativa.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Section>
  )
};

export default Cta;
