export const rootPaths = {
  registrationRoot: '/registration',
  errorsRoot: '/errors',
  authRoot: '/authentication',
};

export default {
  starter: '/',
  integracaoDatto: '/documentation/integration/datto-rmm',
  landing: '/landing',
  privacyPolicy: '/politicas-de-privacidade',
  login: '/login',
  authorized: '/authorized',
  error403: `${rootPaths.errorsRoot}/403`,
  error404: `${rootPaths.errorsRoot}/404`,
  error500: `${rootPaths.errorsRoot}/500`,
  error403: `${rootPaths.errorsRoot}/access-denied`
};


