import App from 'App';
import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import paths, { rootPaths } from './paths';

import ErrorLayout from '../layouts/ErrorLayout';
import MainLayout from '../layouts/MainLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Authorized from 'components/authentication/Authorized';
import Login from 'components/authentication/Login';
import Error403 from 'components/errors/Error403';
import Landing from 'components/pages/landing/Landing';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import FaqAccordion from 'components/pages/faq/faq-accordion/FaqAccordion';
import { Integration } from 'components/pages/integration/integration';

const routes = [
  {
    element: <App />,
    children: [
      {
        path: paths.starter,
        element: <Landing />
      },
      {
        path: paths.integracaoDatto,
        element: <Integration />
      },
      {
        path: paths.privacyPolicy,
        element: <PrivacyPolicy />
      },
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error403,
            element: <Error403 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        element: <AuthSimpleLayout />,
        children: [
          {
            path: paths.login,
            element: <Login />
          },
          {
            path: paths.authorized,
            element: <Authorized />
          }
        ]
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: '*',
            element: <Navigate to={paths.error404} replace />
          }
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
