import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
import { useAppContext } from 'providers/AppProvider';
import React, { useState } from 'react';
import { Anchor, Button, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LandingRightSideNavItem = () => {
  const {
    config: { isDark }
  } = useAppContext();

  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  return (
    <Nav navbar className="ms-auto align-items-lg-center" variant={isDark ? 'dark' : 'light'}>
      <Nav.Item as='li'>
        <Nav.Link
          style={{ color: `${isDark ? '' : '#9DA9BB'}` }}
          to="#inicio"
          className="fs-9 mx-2"
          href="#inicio"
        >
          início
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Anchor}
          to="#!"
          style={{ color: `${isDark ? '' : '#9DA9BB'}` }}
          className="fs-9 mx-2"
          href="#recursos"
        >
          Recursos
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as='li'>
        <Nav.Link
          to="#precos"
          style={{ color: `${isDark ? '' : '#9DA9BB'}` }}
          className="fs-9 mx-2"
          href="#precos"
        >
          Preços
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Anchor}
          to="#!"
          style={{ color: `${isDark ? '' : '#9DA9BB'}` }}
          className="fs-9 mx-2"
          href="#contato"
        >
          Contato
        </Nav.Link>
      </Nav.Item>
      <Button
        as={Link}
        size='sm'
        className='mx-sm-1 mx-md-3 m-sm-3 py-1 bg-transparent fs-9 border-md-primary text-primary'
        to='https://api.whatsapp.com/send?phone=+5515996055486&text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento.'
        target='_blank'
      >
        <FontAwesomeIcon style={{ marginRight: 5 }} icon={faWhatsapp} /> Solicite um orçamento
      </Button>

      <Nav.Item className="me-2 d-none d-lg-block">
        <ThemeControlDropdown iconClassName="fs-9" />
      </Nav.Item>
      <Nav.Item>
        <Link
          className="fs-9 mx-2 text-primary"
          to={"https://app.mspdesk.com.br"}
        /* target='_blank' */
        >
          Entrar
          <FontAwesomeIcon style={{ marginLeft: 4 }} icon={faUserAlt} />
        </Link>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
