import NavbarStandard from "../landing/NavbarStandard";
import FooterIntegration from "./FooterIntegration";
import IntegrationTest from "./integrationTest";
import React from "react";

export const Integration = () => {
    return (
        <>
            <NavbarStandard />
            <IntegrationTest />
            <FooterIntegration />
        </>
    );
};
