import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import Slider from 'react-slick';
import { isIterableArray } from 'helpers/utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonials from 'assets/img/logos/testimonialList';
import { useAppContext } from 'providers/AppProvider';

const TestimonialItem = ({
  description,
  author,
  designation,
  companyImage,
  darkCompanyImage,
  size,
  isDark
}) => {
  const imageToShow = isDark ? darkCompanyImage : companyImage;

  return (
    <div className="px-5 px-sm-6">
      <p className="fs-sm-8 fs-md-7 fst-italic text-1100">{description}</p>
      <p className="fs-9 text-600">
        - {author}, {designation}
      </p>
      <img className="w-auto mx-auto" src={imageToShow} alt="" height={size} />
    </div>
  );
};

TestimonialItem.propTypes = {
  description: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  companyImage: PropTypes.string.isRequired,
  darkCompanyImage: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  isDark: PropTypes.bool.isRequired
};

const settings = {
  infinite: true,
  speed: 500,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};

const Testimonial = () => {
  const {
    config: { isDark }
  } = useAppContext();

  return (
    <Section style={{ height: '70vh' }} className="text-center bg-200">
      <Row className="justify-content-center">
        <Col xs={10} lg={9} xl={8}>
          <Slider {...settings}>
            {isIterableArray(testimonials) &&
              testimonials.map((testimonial, index) => (
                <TestimonialItem
                  {...testimonial}
                  isDark={isDark}
                  key={index}
                  darkCompanyImage={testimonial.darkCompanyImage}
                />
              ))}
          </Slider>
        </Col>
      </Row>
    </Section>
  );
};

export default Testimonial;
