import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";

export default [
  {
    media: { icon: faHeadset, color: '', className: 'fs-3' },
    title: 'Gestão completa a chamados',
    description:
      "Gestão de chamados avançada, abrangendo abertura, acompanhamento e categorização para um controle eficaz e intuitivo."
  },
  {
    media: { icon: ['fa', 'envelope'], color: '', className: 'fs-3' },
    title: 'Integração com email',
    description:
      'Centraliza a comunicação ao permitir que os clientes abram chamados por email e interajam com a equipe técnica.'
  },
  {
    media: { icon: faClock, color: '', className: 'fs-3' },
    title: 'Monitoramento SLA',
    description:
      'Assegura que os tempos de resposta e de resolução estejam dentro dos padrões estabelecidos, promovendo maior satisfação com seus clientes.'
  },
  {
    media: { icon: ['fa', 'file-alt'], color: '', className: 'fs-3' },
    title: 'Relatórios detalhados',
    description:
      'Fornece análises detalhadas sobre o desempenho da equipe, facilitando a tomada de decisões fundamentadas em dados.'
  },
];
