import { Accordion, Card, Modal, Table } from "react-bootstrap";
import genericImage from "../../../assets/img/generic/1.jpg";
import { useAppContext } from "providers/AppProvider";
import { Link } from "react-router-dom";
import { useState } from "react";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

export default function Item03Accordion({ activeKeys, toggleAccordionItem }) {

    const [selectedImage, setSelectedImage] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [innerActiveKey, setInnerActiveKey] = useState(null); // Estado para o accordion interno
    const {
        config: { isDark }
    } = useAppContext();

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setModalShow(true);
    };

    const handleCloseModal = () => {
        setModalShow(false);
    };

    return (
        <Accordion.Item eventKey="2">
            <Accordion.Header onClick={() => toggleAccordionItem("2")}>
                Configurar sincronização de dispositivos
            </Accordion.Header>
            <Accordion.Body>
                <p className="mb-3">
                    O próximo passo é configurar a sincronização de dispositivos entre o Datto RMM e MSP Desk.
                    No card setup de integração, clique na aba <strong> Dispositivos</strong>.
                </p>
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            É considerado uma boa prática configurar o mapeamento de sites da organização antes de habilitar a
                            sincronização de dispositivos. Consulte Configurar mapeamento de organização (site). Configurar a sincronização
                            de dispositivos antes de finalizar o site do Datto RMM para a empresa do MSP Desk pode levar a itens de
                            configuração duplicados no MSP Desk se o mapeamento de sites for alterado ou se dispositivos mudarem de site,
                            uma vez que os itens de configuração no MSP Desk não são excluídos quando um dispositivo é movido ou deletado
                            no Datto RMM.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <img
                    className="mb-3 ms-3"
                    src={genericImage}
                    alt="Descrição da Imagem"
                    style={{ cursor: 'pointer', width: '7vw', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                    onClick={() => handleImageClick(genericImage)}
                />
                <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                    <Card.Body style={{ maxHeight: '30vh', paddingTop: '5px', paddingBottom: '7px' }}>
                        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                        <Card.Text>
                            No MSP Desk, os dispositivos instalados para uma empresa cliente são chamados de itens de configuração. Como todos os itens de
                            configuração são baseados em um produto, os dispositivos Datto RMM devem ser mapeados para o MSP Desk no nível do produto.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <ol>
                    <li className="ms-3">
                        <p className="mb-3">
                            Você pode ativar ou desativar o toggle <strong> Map Devices</strong> a qualquer momento para controlar se a sincronização
                            de dispositivos está
                            ativa ou não. Sua configuração selecionada será salva e persistirá na próxima vez que a página for acessada, sem precisar clicar
                            no botão <strong> Salvar</strong>.
                        </p>
                        <Card className="mb-4 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '5px', paddingBottom: '7px' }}>
                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                <Card.Text>
                                    Você pode desejar manter esse toggle desligado enquanto trabalha na sua configuração e salva suas alterações.
                                    Depois, você pode ativar o toggle sempre que estiver pronto para iniciar oficialmente a sincronização de
                                    dispositivos com suas atualizações.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </li>
                    <li className="ms-3">
                        <p className="mb-3">
                            No menu suspenso <strong> Material Code</strong>, selecione o código de material do MSP Desk que será usado para
                            todos os dispositivos
                            mapeados do Datto RMM para o MSP Desk.
                        </p>
                        <Card className="mb-4 col-xl-12" style={{ borderLeft: '5px solid #53A633' }}>
                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '5px', paddingBottom: '7px' }}>
                                <Card.Text>
                                    Você pode desejar manter esse toggle desligado enquanto trabalha na sua configuração e salva suas alterações.
                                    Depois, você pode ativar o toggle sempre que estiver pronto para iniciar oficialmente a sincronização de
                                    dispositivos com suas atualizações.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </li>
                    <li className="ms-3">
                        <p className="mb-3">
                            Especifique como o Datto RMM deve sincronizar as informações do dispositivo com os itens de configuração do MSP Desk.
                            Você pode ativar ou desativar as seguintes opções:
                        </p>
                        <ul className="mb-3">
                            <li>
                                <strong> Sobrescrever o Nome de Referência do PSA quando for diferente do Nome do Host do RMM
                                </strong> (selecionado por padrão)</li>
                            <li>
                                <strong> Sobrescrever o Número de Referência do PSA quando for diferente do ID do Dispositivo RMM </strong>
                                (selecionado por padrão)
                            </li>
                        </ul>
                        <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid #CC6CE7' }}>
                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '12px', paddingBottom: '12px' }}>
                                <Card.Title style={{ color: '#CC6CE7' }}>EXEMPLO</Card.Title>
                                <Card.Text>
                                    Na sincronização inicial, se o Datto RMM encontrar um item de configuração correspondente no MSP Desk para um
                                    dispositivo Datto RMM e esse item de configuração tiver um nome de referência,
                                    o valor no campo <strong> Nome de Referência </strong>
                                    será sobrescrito se a opção <strong> Sobrescrever o Nome de Referência do PSA quando for diferente do Nome do Host do RMM </strong>
                                    estiver ativada. A mesma lógica será aplicada em qualquer sincronização subsequente.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="mb-4 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                <Card.Text>
                                    Se as opções de sobrescrita não estiverem ativadas no Datto RMM e os campos <strong> Nome de Referência </strong>
                                    e <strong> Número de Referência </strong> para
                                    o item de configuração correspondente estiverem vazios no MSP Desk, os campos serão preenchidos com o nome do host e o ID
                                    do dispositivo na sincronização inicial do dispositivo.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </li>
                    <li className="ms-3">
                        <p className="mb-3">
                            Em seguida, mapeie os tipos de dispositivos Datto RMM para produtos e tipos de itens de configuração do MSP Desk.
                        </p>
                        <Table
                            className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`}
                            striped
                            bordered
                            hover
                        >
                            <thead>
                                <tr>
                                    <th>Campo</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Tipo de dispositivo</td>
                                    <td>Tipo de dispositivo conforme exibido no Datto RMM.</td>
                                </tr>
                                <tr>
                                    <td>Produto MSP Desk</td>
                                    <td>
                                        <p className="mb-1">
                                            Selecione um produto existente do MSP Desk para mapear ao seu tipo de dispositivo do Datto RMM.
                                            Você pode não ver todos os seus produtos do MSP Desk rolando
                                            pelo menu suspenso do <strong> Produto do MSP Desk</strong>,
                                            mas pode buscar qualquer produto usando a barra de busca.
                                        </p>
                                        <p className="mb-1">
                                            Opte por não sincronizar um tipo de dispositivo selecionando
                                            <strong> Não sincronize</strong>. Se o fizer, também deve
                                            selecionar <strong> Nãp mapear</strong> na coluna do <strong> Tipo de Item de Configuração do MSP Desk</strong>.
                                        </p>
                                        <p className="mb-2">
                                            Alternativamente, você pode permitir que o sistema crie um produto correspondente no MSP Desk selecionando
                                            <strong> Criar novo</strong>. O nome do novo produto será prefixado
                                            por <strong> RMM_ </strong> (por exemplo, <strong> RMM_Laptop</strong>).
                                            Isso ajudará a diferenciar
                                            produtos do Datto RMM de produtos existentes no MSP Desk.
                                        </p>
                                        <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                                <Card.Text>
                                                    Recomendamos que você permita que o sistema crie um produto correspondente se não vir o
                                                    produto do MSP Desk correspondente.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                                <Card.Text>
                                                    Nomes de produtos criados pelo sistema (por exemplo,
                                                    <strong> RMM_Laptop</strong>, <strong> RMM_Desktop</strong>, e assim por diante)
                                                    não terão categorias de produtos no MSP Desk por padrão. Os dispositivos serão agrupados em uma categoria
                                                    de produto não categorizada. Para alterar isso, siga estas etapas no MSP Desk: Navegue até o <strong> Menu PSA do
                                                        MSP Desk &gt; Admin &gt; Recursos & Configurações &gt; Produtos, Serviços e Inventário &gt; Produtos &gt; busque o nome do
                                                        produto (por exemplo, RMM_Laptop) &gt; selecione o menu de contexto ao lado do produto e clique em Editar Produto
                                                        &gt; selecione o botão de contexto ao lado da categoria do produto &gt; atribua uma categoria de produto &gt; Salvar &
                                                        Fechar</strong>. Isso atribuirá a categoria de produto selecionada a dispositivos existentes e futuros desse nome de produto.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                                <Card.Text>
                                                    Antes do <a target="_blank" href={" https://rmm.datto.com/help/en/Content/0HOME/ReleaseNotes/2023/ReleaseNotesDattoRMMv12.0.0.htm"}>
                                                        lançamento do Datto RMM 12.0.0</a>, o prefixo usado era <strong> AEM_</strong>
                                                    (por exemplo, <strong> AEM_Laptop</strong>). Mapeamentos de
                                                    produtos existentes onde o prefixo <strong> AEM_</strong> é usado permanecem inalterados, mas todos os novos produtos criados
                                                    automaticamente usarão o prefixo <strong> RMM_</strong>.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="mb-4 col-xl-12" style={{ borderLeft: '5px solid #53A633' }}>
                                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '5px', paddingBottom: '7px' }}>
                                                <Card.Text>
                                                    Para informações sobre como o campo <strong> Produto</strong> é sincronizado para itens de configuração novos e existentes,
                                                    consulte a <Link>lógica e frequência de sincronização de dispositivos</Link>.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tipo de Item de Configuração do MSP Desk</td>
                                    <td>
                                        <p className="mb-3">
                                            Opcionalmente, selecione um tipo de item de configuração do MSP Desk correspondente para cada tipo de dispositivo
                                            do Datto RMM ou selecione <strong> Não mapear</strong>. O menu suspenso contém todos os tipos
                                            de itens de configuração conforme configurado no MSP Desk.
                                        </p>

                                        <Card className="mb-2 col-xl-12" style={{ borderLeft: '5px solid #53A633' }}>
                                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '5px', paddingBottom: '7px' }}>
                                                <Card.Text>
                                                    No MSP Desk, um tipo de item de configuração é a classificação de um item de configuração.
                                                    Ele também determina quais UDFs (campos definidos pelo usuário) disponíveis você pode aplicar.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <li className="ms-3">
                            <p className="mb-3">
                                Na seção <strong> Mapeamento de Campos Definidos pelo Usuário</strong>, selecione <strong> Todas</strong> as caixas de seleção
                                para os UDFs que você gostaria
                                de mapear como UDFs de itens de configuração do MSP Desk. Selecione a caixa de seleção Todos para selecionar todos os
                                UDFs. Selecione Todos novamente para remover todas as seleções, ou selecione as caixas de seleção individuais dos UDFs
                                novamente para remover seleções individuais. Para saber mais sobre UDFs, consulte <Link> Campos Definidos pelo Usuário</Link> em
                                <Link> Configurações Globais</Link>.
                            </p>
                            <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                <Card.Body style={{ maxHeight: '30vh', paddingTop: '7px', paddingBottom: '7px' }}>
                                    <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                    <Card.Text>
                                        Apenas os UDFs globais serão sincronizados, seja com seu rótulo padrão ou com seu rótulo global, se configurado.
                                        Os rótulos globais aparecem à direita dos rótulos padrão. Os rótulos de site (rótulos de UDF no nível do site) não
                                        serão sincronizados e não são exibidos.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                    <Card.Text>
                                        Ao mapear campos definidos pelo usuário, se já existir um campo com o mesmo nome, você não poderá selecionar outro
                                        campo com o mesmo nome. Essa restrição se aplica apenas aos campos definidos pelo usuário do MSP Desk, que devem ter
                                        nomes únicos. Em contraste, os campos definidos pelo usuário do Datto RMM não têm essa restrição de nomenclatura.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </li>
                        <li className="ms-2">
                            <p className="mb-4">
                                Quando terminar, clique em <strong> Salvar</strong> no canto superior direito ou inferior direito da página
                                para preservar suas alterações,
                                enviar sua configuração de mapeamento para o banco de dados e iniciar a sincronização.
                            </p>
                        </li>
                    </li>
                </ol>
                <p className="fs-7 mb-3">
                    Lógica e frequência de sincronização de dispositivos.
                </p>
                <Accordion activeKey={innerActiveKey} className="col-lg-12">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => setInnerActiveKey(innerActiveKey === "0" ? null : "0")}>
                            Lógica de sincronização de dispositivo
                        </Accordion.Header>
                        <AccordionBody>
                            <p>
                                O Datto RMM tentará encontrar um item de configuração correspondente no MSP Desk aplicando a seguinte lógica
                                de sincronização de dispositivos:
                            </p>
                            <ol>
                                <li>
                                    Verifique se o dispositivo foi auditado ou se atende aos critérios de exclusão de auditoria.
                                    <ul className="mb-2">
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mt-2 mb-2">
                                            Se a resposta for não, pule o dispositivo.
                                        </li>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se a resposta for sim, vá para o próximo passo.
                                        </li>
                                        <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                            <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                                                <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                                <Card.Text>
                                                    Os seguintes tipos de dispositivos estão excluídos da exigência de auditoria: dispositivos de rede Datto,
                                                    dispositivos de continuidade Datto e dispositivos de rede adicionados ao Datto RMM há mais de 24 horas.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </ul>
                                </li>
                                <li>
                                    Verifique se o dispositivo já está mapeado para um item de configuração do MSP Desk.
                                    <ul className="mb-2">
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mt-2 mb-2">
                                            Se um mapeamento for encontrado, sincronize o dispositivo.
                                        </li>
                                        <li className="mb-2" style={{ listStyleType: 'lower-alpha' }}>
                                            Se nenhum mapeamento for encontrado, vá para o próximo passo.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mb-2">
                                    Procure um item de configuração do MSP Desk cujo campo nativo de RMMDeviceUID corresponda ao ID do dispositivo do Datto RMM.
                                    <Card className="mb-3 col-xl-12 mt-2" style={{ borderLeft: '5px solid blue' }}>
                                        <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                            <Card.Text>
                                                O campo nativo de <strong> RMMDeviceUID</strong> do MSP Desk não é visível na interface do usuário do MSP Desk
                                                e não pode ser editado.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <ul>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se um item de configuração correspondente for encontrado e estiver ativo, mapeie e sincronize o dispositivo. Se estiver inativo,
                                            ative-o, depois mapeie e sincronize o dispositivo.
                                        </li>
                                        <li style={{ listStyleType: 'lower-alpha' }}>
                                            Se nenhum mapeamento for encontrado, vá para o próximo passo.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Na organização do MSP Desk mapeada para o site ou sites do Datto RMM, procure um item de configuração do MSP Desk que tenha o mesmo
                                    nome de host (no MSP Desk: Nome de Referência) e número de série que o dispositivo do Datto RMM.
                                    <Card className="mb-3 col-xl-12 mt-2" style={{ borderLeft: '5px solid blue' }}>
                                        <Card.Body style={{ maxHeight: '30vh', paddingTop: '10px', paddingBottom: '10px' }}>
                                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                            <Card.Text>
                                                Para dispositivos de rede Datto, o número de série é preenchido como o endereço MAC.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <ul>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se apenas um item de configuração correspondente for encontrado e estiver ativo, preencha o campo nativo de RMMDeviceUID do
                                            MSP Desk com o ID do dispositivo do Datto RMM, depois mapeie e sincronize o dispositivo. Se o item de configuração estiver
                                            inativo, ative-o, preencha o campo nativo de RMMDeviceUID, depois mapeie e sincronize o dispositivo.
                                        </li>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se mais de um item de configuração correspondente for encontrado e estiverem ativos, identifique o mais antigo, preencha o campo
                                            nativo de RMMDeviceUID do MSP Desk com o ID do dispositivo do Datto RMM, depois mapeie e sincronize o dispositivo. Se os itens de
                                            configuração estiverem inativos, identifique o mais antigo, ative-o, preencha o campo nativo de RMMDeviceUID, depois mapeie e
                                            sincronize o dispositivo.
                                        </li>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se nenhum mapeamento for encontrado, vá para o próximo passo.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Verifique se há vários dispositivos com o mesmo número de série no site ou sites do Datto RMM que estão mapeados para a
                                    organização do MSP Desk.
                                    <ul>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2 mt-2">
                                            Se vários dispositivos correspondentes forem encontrados, vá para o passo 7.
                                        </li>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se apenas um dispositivo correspondente for encontrado, vá para o próximo passo.
                                        </li>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se nenhum dispositivo correspondente for encontrado, vá para o passo 7.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Procure um item de configuração do MSP Desk que tenha o mesmo número de série que o dispositivo do Datto RMM.
                                    <ul>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2 mt-2">
                                            Se um mapeamento for encontrado, repita as mesmas verificações dos passos 4.a e 4.b.
                                        </li>
                                        <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                                            Se nenhum mapeamento for encontrado, vá para o próximo passo.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mb-3">
                                    Crie um novo item de configuração do MSP Desk, preencha o campo nativo de <strong> RMMDeviceUID</strong>, depois mapeie e
                                    sincronize o dispositivo.
                                </li>
                                <p className="fs-7 mb-2">
                                    Alguns pontos para considerar
                                </p>
                                <ul className="row gy-2">
                                    <li>
                                        Quando um dispositivo é movido de um site para outro no Datto RMM, e o novo site está mapeado para uma organização do MSP Desk,
                                        o item de configuração correspondente do MSP Desk será desativado na organização original do MSP Desk. Na nova organização
                                        do MSP Desk, um item de configuração existente com um <strong> RMMDeviceUID</strong> correspondente será ativado ou um novo item de
                                        configuração será criado.
                                    </li>
                                    <li>
                                        Quando um dispositivo é movido de um site para outro no Datto RMM, e o novo site não está mapeado para
                                        nenhuma organização do MSP Desk, o item de configuração do MSP Desk anteriormente vinculado permanecerá na organização
                                        original do MSP Desk e será desativado.
                                    </li>
                                    <li>
                                        Quando um dispositivo é excluído no Datto RMM, o item de configuração correspondente do MSP Desk será desativado.
                                    </li>
                                    <li>
                                        Se um item de configuração do MSP Desk foi definido como inativo, mas o dispositivo do Datto RMM ainda está sincronizando com ele,
                                        o item de configuração será ativado novamente.
                                    </li>
                                    <li>
                                        Os campos de <strong> Produto</strong> e <strong> Tipo de Item de Configuração</strong> de um item de configuração do
                                        MSP Desk são sincronizados (aplicados) apenas quando um novo item de configuração é criado.
                                        Quando um item de configuração existente é sincronizado, os campos de <strong> Produto</strong> e <strong> Tipo de Item de
                                            Configuração</strong> não serão sobrescritos.
                                    </li>
                                    <li>
                                        Se você alterar o rótulo padrão de um UDF nas Configurações Globais no Datto RMM, você precisará executar novamente a configuração
                                        de sincronização de dispositivos para garantir que o mapeamento do UDF seja atualizado no MSP Desk. Consulte Configurar sincronização
                                        de dispositivos.
                                    </li>
                                </ul>
                            </ol>
                        </AccordionBody>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => setInnerActiveKey(innerActiveKey === "1" ? null : "1")}>
                            Frequência de sincronização de dispositivo
                        </Accordion.Header>
                        <AccordionBody>
                            <p className="fs-8">
                                Novos dispositivos (não sincronizados) serão sincronizados imediatamente após a realização de uma auditoria inicial.
                            </p>
                            <p className="fs-8">
                                Para dispositivos existentes (sincronizados anteriormente), uma sincronização de dispositivo será acionada uma vez a cada 24 horas.
                                Se, no momento da sincronização, o mapeamento existente não for mais válido (por exemplo, o item de configuração foi excluído,
                                os detalhes foram alterados, etc.), a lógica de sincronização do dispositivo será reaplicada.
                            </p>
                        </AccordionBody>
                    </Accordion.Item>
                </Accordion>
            </Accordion.Body>
            {/* Modal com a imagem maior */}
            <Modal
                show={modalShow}
                onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onClick={handleCloseModal} // Fecha o modal ao clicar fora
            >
                <Modal.Body onClick={(e) => e.stopPropagation()}> {/* Impede fechamento ao clicar na imagem */}
                    <img
                        src={selectedImage} // Usa a imagem selecionada
                        alt="Imagem Ampliada"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Modal.Body>
            </Modal>
        </Accordion.Item>
    )
}