import MSPDeskDark from 'assets/img/generic/MSP-Desk-HeroImg-Dark.jpeg';
import MSPDesk from 'assets/img/generic/MSP-Desk-HeroImg2.jpg';
import Section from 'components/common/Section';
import { useAppContext } from 'providers/AppProvider';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SectionHeader from './SectionHeader';

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useAppContext();

  return (
    <Section
      id="inicio"
      className="py-0 overflow-hidden"
      data-bs-theme="light"
      /* image={bg1} */
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-8 pb-lg-9 pb-xl-0">
        <Col
          md={12}
          lg={12}
          xl={7}
          className="pb-7 pb-xl-7 text-center text-xl-start"
        >

          <SectionHeader
            title={<span style={{ color: isDark ? '#ded8d7' : '' }}>MSP Desk: A Solução Completa para Organizar Seu Atendimento</span>}
            subtitle={"Facilite a gestão dos chamados de suporte e aumente a eficiência da sua equipe técnica com o MSP Desk. Este sistema totalmente online simplifica o processo de atendimento, tornando sua equipe mais produtiva e eficaz."}
          />

        </Col>
        <Col
          xl={{ span: 5, offset: 0 }}
          className="align-self-end pb-8 mt-xl-0"
        >
          <Link to="/" className="img-landing-banner">
            <img
              style={{ marginBottom: '' }}
              className="img-fluid"
              src={isDark ? MSPDeskDark : MSPDesk}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;