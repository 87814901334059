import useHttp from 'helpers/HttpMethods';

const http = useHttp();
const api = {
  dashboards: {
    getTicketsByCatalog: async filter => await http.get(`/dashboards/tickets/by-catalog?${filter}`),
    getTicketsByCustomer: async filter => await http.get(`/dashboards/tickets/by-customer?${filter}`),
    getTicketsByAgent: async filter => await http.get(`/dashboards/tickets/by-agent?${filter}`),
    getTicketsByPriority: async filter => await http.get(`/dashboards/tickets/by-priority?${filter}`),
    getTicketsByServiceGroup: async filter => await http.get(`/dashboards/tickets/by-service-group?${filter}`),
    getTicketsByStatus: async filter => await http.get(`/dashboards/tickets/by-status?${filter}`),
    getTicketsUnassigned: async filter => await http.get(`/dashboards/tickets/unassigned?${filter}`),
    getMyTickets: async filter => await http.get(`/dashboards/tickets/my-tickets?${filter}`),
    getTicketsResponseExpireds: async filter => await http.get(`/dashboards/tickets/response-expireds?${filter}`),
    getTicketsSolutionExpireds: async filter => await http.get(`/dashboards/tickets/solution-expireds?${filter}`),
    getTicketsWithSlaPaused: async filter => await http.get(`/dashboards/tickets/sla-paused?${filter}`),
    getTicketsOpened: async filter => await http.get(`/dashboards/tickets/opened?${filter}`),
    getTicketsClosed: async filter => await http.get(`/dashboards/tickets/closed?${filter}`),
    getPreTickets: async () => await http.get(`/dashboards/tickets/pre-tickets`),
    getAnswered: async filter => await http.get(`/dashboards/tickets/answered?${filter}`)
  },
  customers: {
    list: async filter => await http.get(`/customers?${filter}`),
    listByContact: async contactId => await http.get(`/customers/by-contact/${contactId}`),
    findById: async id => await http.get(`/customers/${id}`),
    create: async body => await http.post(body, '/customers'),
    edit: async (id, body) => await http.put(body, `/customers/${id}`)
  },
  contacts: {
    list: async filter => await http.get(`/contacts?${filter}`),
    create: async body => await http.post(body, `/contacts`),
    edit: async (contactId, body) => await http.put(body, `/contacts/${contactId}`)
  },
  customersContacts: {
    list: async (id, filter) => await http.get(`/customers/${id}/contacts?${filter}`),
    add: async body => await http.post(body, `/contacts`),
    edit: async (contactId, body) => await http.put(body, `/contacts/${contactId}`),
    associate: async (customerId, contactId) => await http.putWithoutBody(`/customers/${customerId}/contacts/${contactId}`),
    unassociate: async (customerId, contactId) => await http.del(`/customers/${customerId}/contacts/${contactId}`)
  },
  customersAddresses: {
    list: async (id, filter) => await http.get(`/customers/${id}/addresses?${filter}`),
    add: async (id, body) => await http.post(body, `/customers/${id}/addresses`),
    edit: async (customerId, addressId, body) => await http.put(body, `/customers/${customerId}/addresses/${addressId}`),
    delete: async (customerId, addressId) => await http.del(`/customers/${customerId}/addresses/${addressId}`)
  },
  allowedDomains: {
    list: async (customerId, filter) => await http.get(`/customers/${customerId}/allowed-domains?${filter}`),
    add: async (customerId, body) => await http.post(body, `/customers/${customerId}/allowed-domains`),
    delete: async (customerId, domainId) => await http.del(`/customers/${customerId}/allowed-domains/${domainId}`),
    edit: async (customerId, domainId, body) => await http.put(body, `/customers/${customerId}/allowed-domains/${domainId}`)
  },
  agents: {
    list: async filter => await http.get(`/users?${filter}`),
    listSummaryData: async () => await http.get("/users/summary-data"),
    create: async body => await http.post(body, '/users'),
    edit: async (id, body) => await http.put(body, `/users/${id}`),
    updatePassword: async (id, body) => await http.put(body, `/users/${id}/update-password`),
    myProfile: async id => await http.get(`/users/${id}/my-profile`),
    getUserIdAuthenticated: async () => await http.getInFormatText('/users/get-id-authenticated-user')
  },
  agentPhoto: {
    find: async id => await http.getWithAccept(`/users/${id}/photo`, '*/*'),
    update: async (id, photo) => await http.putFile(photo, `/users/${id}/photo`),
    delete: async id => await http.del(`/users/${id}/photo`)
  },
  serviceGroups: {
    list: async filter => await http.get(`/service-groups?${filter}`),
    listSummaryData: async filter => await http.get(`/service-groups/summary-data?${filter}`),
    listByUser: async () => await http.get('/service-groups/by-user'),
    create: async body => await http.post(body, '/service-groups'),
    edit: async (id, body) => await http.put(body, `/service-groups/${id}`)
  },
  serviceGroupsAgents: {
    list: async id => await http.get(`/service-groups/${id}/agents`),
    associate: async (departmentId, agentId) => await http.putWithoutBody(`/service-groups/${departmentId}/agents/${agentId}`),
    unassociate: async (departmentId, agentId) => await http.del(`/service-groups/${departmentId}/agents/${agentId}`),
  },
  serviceCatalogsServiceGroups: {
    list: async id => await http.get(`/service-catalogs/${id}/service-groups`),
    associate: async (serviceCatalogId, serviceGroupId) => await http.putWithoutBody(`/service-catalogs/${serviceCatalogId}/service-groups/${serviceGroupId}`),
    unassociate: async (serviceCatalogId, serviceGroupId) => await http.del(`/service-catalogs/${serviceCatalogId}/service-groups/${serviceGroupId}`)
  },
  categories: {
    list: async filter => await http.get(`/categories?${filter}`),
    listByServiceCatalog: async (serviceCatalogId, filter) => await http.get(`/categories/by-service-catalog/${serviceCatalogId}?${filter}`),
    create: async body => await http.post(body, '/categories'),
    edit: async (id, body) => await http.put(body, `/categories/${id}`)
  },
  subcategories: {
    list: async filter => await http.get(`/subcategories?${filter}`),
    listByCategory: async (categoryId, filter) => await http.get(`/subcategories/by-category/${categoryId}?${filter}`),
    create: async body => await http.post(body, '/subcategories'),
    edit: async (id, body) => await http.put(body, `/subcategories/${id}`)
  },
  subcategoriesCategories: {
    unassociate: async (subcategoryId, categoryId) => await http.del(`/subcategories/${subcategoryId}/categories/${categoryId}`)
  },
  typeTickets: {
    list: async filter => await http.get(`/types-ticket?${filter}`),
    create: async body => await http.post(body, '/types-ticket'),
    edit: async (id, body) => await http.put(body, `/types-ticket/${id}`)
  },
  profiles: {
    list: async filter => await http.get(`/profiles?${filter}`),
    create: async body => await http.post(body, `/profiles`),
    edit: async (id, body) => await http.put(body, `/profiles/${id}`),
    delete: async id => await http.del(`/profiles/${id}`),
    listPermissions: async id => await http.get(`/profiles/${id}/permissions`),
    associatePermissions: async (id, body) => await http.put(body, `/profiles/${id}/permissions/add-batch`),
    unassociatePermissions: async (id, body) => await http.delWithBody(body, `/profiles/${id}/permissions/remove-batch`)
  },
  tickets: {
    list: async (filter) => await http.get(`/tickets?${filter}`),
    listKanban: async filter => await http.get(`/tickets/list-kanban?${filter}`),
    listByCustomer: async (customerId, filter) => await http.get(`/tickets/by-customer/${customerId}?${filter}`),
    getDataFromPreticket: async preticketId => await http.get(`/tickets/from-preticket/${preticketId}`),
    findById: async id => await http.get(`/tickets/${id}`),
    create: async body => await http.post(body, '/tickets'),
    createFromPreticket: async (preticketid, body) => await http.post(body, `/tickets/from-preticket/${preticketid}`),
    createFromPreticketAndComplete: async (preticketid, body) => await http.post(body, `/tickets/from-preticket-and-complete/${preticketid}`),
    createAndComplete: async body => await http.post(body, '/tickets/create-and-complete'),
    edit: async (id, body) => await http.patch(body, `/tickets/${id}`),
    delete: async id => await http.del(`/tickets/${id}`),
    start: async id => await http.putWithoutBody(`/tickets/${id}/start`),
    pause: async (id, body) => await http.put(body, `/tickets/${id}/pause`),
    complete: async id => await http.putWithoutBody(`/tickets/${id}/complete`),
    reopen: async id => await http.putWithoutBody(`/tickets/${id}/reopen`),
    listLogs: async id => await http.get(`/tickets/${id}/logs`),
    getTimeSpent: async id => await http.getInFormatText(`/tickets/${id}/get-time-spent`),
    print: async id => await http.getPdf(`/tickets/${id}/print`)
  },
  ticketFiles: {
    attach: async (file, id) => await http.postFile(file, `/tickets/${id}/files`),
    list: async id => await http.get(`/tickets/${id}/files`),
    delete: async (ticketId, fileId) => await http.del(`/tickets/${ticketId}/files/${fileId}`)
  },
  ticketNotes: {
    list: async id => await http.get(`/tickets/${id}/notes`),
    add: async (id, body) => await http.post(body, `/tickets/${id}/notes`),
    addAndSetAsPending: async (id, body) => await http.post(body, `/tickets/${id}/notes/add-and-set-as-pending`),
    addAndSetAsCompleted: async (id, body) => await http.post(body, `/tickets/${id}/notes/add-and-set-as-completed`),
    reply: async (id, body) => await http.post(body, `/tickets/${id}/notes/reply`),
    replyAndSetAsPending: async (id, body) => await http.post(body, `/tickets/${id}/notes/reply-and-set-as-pending`),
    replyAndSetAsCompleted: async (id, body) => await http.post(body, `/tickets/${id}/notes/reply-and-set-as-completed`),
    forward: async (id, body) => await http.post(body, `/tickets/${id}/notes/forward`),
    edit: async (ticketId, noteId, body) => await http.put(body, `/tickets/${ticketId}/notes/${noteId}`),
    delete: async (ticketId, noteId) => await http.del(`/tickets/${ticketId}/notes/${noteId}`)
  },
  appointments: {
    list: async id => await http.get(`/tickets/${id}/appointments`),
    create: async (ticketId, body) => await http.post(body, `/tickets/${ticketId}/appointments`),
    edit: async (ticketId, appointmentId, body) => await http.put(body, `/tickets/${ticketId}/appointments/${appointmentId}`),
    delete: async (ticketId, appointmentId) => await http.del(`/tickets/${ticketId}/appointments/${appointmentId}`)
  },
  preTickets: {
    list: async filter => await http.get(`/pre-tickets?${filter}`),
    delete: async id => await http.del(`/pre-tickets/${id}`),
    deleteAll: async () => await http.del('/pre-tickets/delete-all'),
    batchDelete: async body => await http.delWithBody(body, '/pre-tickets/batch-delete')
  },
  blacklist: {
    list: async filter => await http.get(`/blacklist?${filter}`),
    add: async body => await http.post(body, '/blacklist'),
    delete: async id => await http.del(`/blacklist/${id}`)
  },
  emailBoxes: {
    list: async filter => await http.get(`/email-boxes?${filter}`),
    create: async body => await http.post(body, '/email-boxes'),
    edit: async (emailBoxId, body) => await http.put(body, `/email-boxes/${emailBoxId}`),
    delete: async emailBoxId => await http.del(`/email-boxes/${emailBoxId}`)
  },
  serviceCatalogs: {
    list: async filter => await http.get(`/service-catalogs?${filter}`),
    listByServiceGroup: async id => await http.get(`/service-catalogs/by-service-group/${id}`),
    listByServiceGroupInCreationTicket: async id => await http.get(`/service-catalogs/by-service-group-creation-ticket/${id}`),
    findById: async id => await http.get(`/service-catalogs/${id}`),
    create: async body => await http.post(body, '/service-catalogs'),
    edit: async (id, body) => await http.put(body, `/service-catalogs/${id}`)
  },
  companies: {
    findById: async id => await http.get(`/companies/${id}`),
    getMyCompanyId: async () => await http.getInFormatText('/companies/get-my-company-id'),
    edit: async (id, body) => await http.put(body, `/companies/${id}`),
    logo: {
      find: async id => await http.getWithAccept(`/companies/${id}/logo`, '*/*'),
      update: async (id, logo) => await http.putFile(logo, `/companies/${id}/logo`),
      delLogo: async id => await http.del(`/companies/${id}/logo`)
    }
  },
  holidays: {
    list: async filter => await http.get(`/holidays?${filter}`),
    add: async body => await http.post(body, '/holidays'),
    edit: async (id, body) => await http.put(body, `/holidays/${id}`),
    delete: async id => await http.del(`/holidays/${id}`)
  },
  businessHours: {
    list: async () => await http.get('/business-hours'),
    edit: async (id, body) => await http.put(body, `/business-hours/${id}`)
  },
  slaPolicies: {
    list: async () => await http.get('/sla-policies'),
    findById: async id => await http.get(`/sla-policies/${id}`),
    activate: async id => await http.putWithoutBody(`/sla-policies/${id}/activate`),
    inactivate: async id => await http.del(`/sla-policies/${id}/inactivate`),
    delete: async id => await http.del(`/sla-policies/${id}`),
    create: async body => await http.post(body, '/sla-policies'),
    edit: async (id, body) => await http.put(body, `/sla-policies/${id}`),
    deleteEscalation: async (slaPolicyId, slaEscalationId) => await http.del(`/sla-policies/${slaPolicyId}/escalations/${slaEscalationId}`)
  },
  pauseReasons: {
    list: async filters => await http.get(`/pause-reasons?${filters}`),
    create: async body => await http.post(body, '/pause-reasons'),
    edit: async (id, body) => await http.put(body, `/pause-reasons/${id}`)
  },
  slaPauses: {
    getActivePause: async ticketId => await http.get(`/tickets/${ticketId}/sla-pauses/get-active`),
    pause: async (ticketId, body) => await http.post(body, `/tickets/${ticketId}/sla-pauses`),
    resume: async ticketId => await http.putWithoutBody(`/tickets/${ticketId}/sla-pauses`)
  },
  defaultFilters: {
    list: async () => await http.get('/default-filters'),
    getByUser: async () => await http.get('/default-filters/by-user')
  },
  scheduledsTickets: {
    list: async filters => await http.get(`/scheduleds-tickets?${filters}`),
    findById: async id => await http.get(`/scheduleds-tickets/${id}`),
    create: async body => await http.post(body, '/scheduleds-tickets'),
    edit: async (id, body) => await http.put(body, `/scheduleds-tickets/${id}`),
    delete: async id => await http.del(`/scheduleds-tickets/${id}`),
    inactivate: async id => await http.del(`/scheduleds-tickets/${id}/inactivate`),
    activate: async id => await http.putWithoutBody(`/scheduleds-tickets/${id}/activate`)
  },
  reports: {
    executiveCustomer: {
      byCatalog: async filters => await http.get(`/reports/executive-customer/by-catalog?${filters}`),
      byTypeTicket: async filters => await http.get(`/reports/executive-customer/by-type-ticket?${filters}`),
      byCategory: async filters => await http.get(`/reports/executive-customer/by-category?${filters}`),
      byPriority: async filters => await http.get(`/reports/executive-customer/by-priority?${filters}`),
      summary: async filters => await http.get(`/reports/executive-customer/summary?${filters}`),
      appointments: async filters => await http.get(`/reports/executive-customer/appointments?${filters}`),
      catalogStatistics: async filters => await http.get(`/reports/executive-customer/catalog-statistics?${filters}`),
    }
  }
};

export default () => api;