import { Accordion, Card, Col, Modal, Row, Table } from "react-bootstrap";
import genericImage from "../../../assets/img/generic/1.jpg";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { Link } from "react-router-dom";
import { useAppContext } from "providers/AppProvider";
import { BsCheckCircle } from "react-icons/bs";
import { useState } from "react";

export function Item02Accordion({ activeKeys, toggleAccordionItem }) {

    const [selectedImage, setSelectedImage] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const {
        config: { isDark }
    } = useAppContext();


    const handleImageClick = (image) => {
        setSelectedImage(image);
        setModalShow(true);
    };

    const handleCloseModal = () => {
        setModalShow(false);
    };

    return (
        <Accordion.Item eventKey="1">
            <Accordion.Header onClick={() => toggleAccordionItem("1")}>
                Configurar mapeamento de organização (site)
            </Accordion.Header>
            <AccordionBody>
                <Col>
                    <Card className="ms-2 mb-3 col-xl-12" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                        <Card.Body className="mb-2" style={{ maxHeight: '116px' }}>
                            <Card.Title className="text-danger">IMPORTANTE</Card.Title>
                            <Card.Text>
                                Reveja a seção de <Link> Permissões </Link>nesse tópico para aprender mais
                                sobre os níveis de securança necessários em ambos Datto RMM e MSP Desk.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <p>Após habilitar a integração, o próximo passo é mapear suas organizações do MSP Desk para os
                        sites do Datto RMM. No card de Configuração de Integração, clique na aba <strong>Sites</strong>:
                    </p>
                    <img
                        className="mb-2 ms-2"
                        src={genericImage}
                        alt="Descrição da Imagem"
                        style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                        onClick={() => handleImageClick(genericImage)}
                    />
                    <Card className="ms-2 mb-3 col-xl-12" style={{ margin: '10px 0', borderLeft: '5px solid blue' }}>
                        <Card.Body style={{ maxHeight: '180px' }}>
                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                            <Card.Text>
                                Esta área permite que você configure os mapeamentos em um estado de rascunho.
                                As alterações não são aplicadas até que você clique em <strong> Salvar</strong>, e a opção de descartar todas as alterações
                                também está disponível. Você também pode excluir mapeamentos existentes e remapear todos ou itens
                                selecionados a qualquer momento. Essas medidas de segurança são descritas em mais detalhes nesta seção.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="ms-2 mb-4 col-xl-12" style={{ margin: '10px 0', borderLeft: '5px solid blue' }}>
                        <Card.Body style={{ maxHeight: '100px' }}>
                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                            <Card.Text>
                                Depois de configurar o mapeamento de sites aqui,
                                você pode editar ou adicionar o mapeamento de empresas nas configurações do site ao editar um site.
                                Consulte o <Link>Mapeamento PSA </Link>.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <p className="fs-5 mb-3">Populando resultados na lista de mapeamentos em potenciais</p>
                    <p className="mb-2">No campo <strong> Tipos de Conta a Mapear</strong>, você pode selecionar qualquer número de tipos de organizações
                        do MSP Desk que deseja recuperar, dependendo dos tipos associados à sua conta
                        do MSP Desk:
                    </p>
                    <img
                        className="mb-3 ms-2"
                        src={genericImage}
                        alt="Descrição da Imagem"
                        style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                        onClick={() => handleImageClick(genericImage)}
                    />
                    <ul>
                        <li className="mb-2">
                            <strong>Selecionar todos:</strong> Popula todos os tipos de organizações disponíveis.
                        </li>
                        <li className="mb-2">
                            <strong>Cliente:</strong> Uma organização para a qual você está vendendo produtos ou serviços.
                        </li>
                        <li className="mb-2">
                            <strong>Lead:</strong> Um tipo de organização usado para indicar um cliente em potencial.
                        </li>
                        <li className="mb-2">
                            <strong>Prospect:</strong> Um tipo de organização usado para indicar um cliente provável.
                        </li>
                        <li className="mb-2">
                            <strong>Inativo:</strong> Um lead que nunca se tornou um cliente.
                        </li>
                        <li className="mb-2">
                            <strong>Cancelamento:</strong> Um tipo de organização do MSP Desk que denota um ex-cliente.
                        </li>
                        <li className="mb-2">
                            <strong>Fornecedor:</strong> Um tipo de organização cuja principal
                            relação comercial com sua empresa é fornecer bens e serviços.
                        </li>
                        <li className="">
                            <strong>Parceiro:</strong> Um tipo de organização atribuído a
                            organizações como VARs, parceiros de terceirização, e assim por diante.
                        </li>
                    </ul>
                    <p className="ms-3 mb-4">
                        Clique no <strong> X</strong> ao lado de qualquer tipo de organização para remover essa seleção dos resultados,
                        ou clique no <strong> X</strong> à direita da caixa de seleção para remover todas as seleções.
                    </p>
                    <p className="fs-5 mb-3">Lista de mapeamentos potenciais</p>
                    <p>
                        A lista de organizações do MSP Desk será populada com base nas suas seleções de
                        <strong> Tipos de Conta a Mapear</strong>.
                        As seleções persistirão na próxima vez que a aba <strong> Sites</strong> for acessada.
                    </p>
                    <img
                        className="mb-3 ms-2"
                        src={genericImage}
                        alt="Descrição da Imagem"
                        style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                        onClick={() => handleImageClick(genericImage)}
                    />
                    {/* TABLE 01 */}
                    <Table className={isDark ? 'text-bg-dark' : 'text-bg-light'} striped bordered hover>
                        <thead>
                            <tr>
                                <th>Campo</th>
                                <th>Ordenável?</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>MSP Desk</td>
                                <td style={{ textAlign: 'center' }}><BsCheckCircle color="green" /></td>
                                <td>Exibe o Nome da Conta conforme configurado na sua conta do MSP Desk.</td>
                            </tr>
                            <tr>
                                <td>Sites</td>
                                <td></td>
                                <td>
                                    <p className="mb-4">
                                        Pode exibir um site na sua conta do Datto RMM que corresponda ao nome da organização do MSP Desk.
                                        Se não houver correspondência sugerida, o campo ficará vazio.
                                        Comece a digitar neste campo para buscar organizações disponíveis do MSP Desk. Apenas sites que ainda não estão
                                        mapeados para uma organização do MSP Desk (seja em estado de rascunho ou salvo) estão disponíveis para seleção.
                                    </p>
                                    <Card className="mb-3 col-xl-8" style={{ borderLeft: '5px solid blue' }}>
                                        <Card.Body style={{ maxHeight: '100px', paddingTop: '16px', paddingBottom: '16px' }}>
                                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                            <Card.Text>
                                                Uma organização do MSP Desk pode ser mapeada para mais de um site do Datto RMM.
                                                Para isso, selecione múltiplos valores para um item.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <p className="fs-7 mb-3">Criando novos sites para mapear</p>
                                    <p className="mb-1">
                                        Você tem a opção de criar um novo site do Datto RMM para uma organização do MSP Desk sem mapeamento.
                                        Para fazer isso, clique no menu suspenso e selecione a opção prefixada com <strong> Novo - </strong>.
                                        Essas seleções são exibidas em uma cor azul clara para facilitar a identificação.
                                        Essa ação será registrada no Log de Atividades. Consulte o <Link> Log de Atividades</Link>.
                                        O novo site será adicionado à sua lista de sites no Datto RMM
                                        (uma vez que o mapeamento seja salvo) e não incluirá o prefixo <strong> Novo - </strong>
                                        em seu nome uma vez criado.
                                        Para saber mais sobre como gerenciar sites no Datto RMM, consulte <Link>Sites</Link>.
                                    </p>
                                    <img
                                        className="mb-3"
                                        src={genericImage}
                                        alt="Descrição da Imagem"
                                        style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                                        onClick={() => handleImageClick(genericImage)}
                                    />
                                    <p className="fs-7 mb-3">Optando por não mapear uma organização</p>
                                    <p className="mb-1">
                                        Nesta coluna, clique no menu suspenso e selecione <strong> Não mapear</strong>.
                                        Itens com essa seleção são categorizados como <strong> Mapeados</strong> porque possuem uma atribuição.
                                        Essas seleções são exibidas em uma cor cinza um pouco mais escura para facilitar a identificação.
                                    </p>
                                    <img
                                        className="mb-3"
                                        src={genericImage}
                                        alt="Descrição da Imagem"
                                        style={{ cursor: 'pointer', width: '250px', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                                        onClick={() => handleImageClick(genericImage)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="mb-1">
                        Clique no <strong> X</strong> ao lado do nome de um site para remover essa seleção
                        ou clique no X à direita da caixa de seleção para limpar todas as seleções.
                    </p>
                    <Row style={{ justifyContent: 'center' }}>
                        <img
                            className="mb-3"
                            src={genericImage}
                            alt="Descrição da Imagem"
                            style={{ cursor: 'pointer', width: '100vw', height: '70px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                            onClick={() => handleImageClick(genericImage)}
                        />
                    </Row>
                    <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                        <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                            <Card.Text>
                                Alterar o nome de um site do Datto RMM não renomeará a organização mapeada do
                                MSP Desk e vice-versa.
                                Se um site do Datto RMM já estiver mapeado para uma organização do MSP Desk
                                e você alterar o nome de qualquer um deles,
                                será necessário reexecutar a análise de mapeamento para ver o nome atualizado.
                                O mapeamento continuará
                                baseado no ID da organização do MSP Desk,
                                mas o novo nome só aparecerá se você reexecutar a análise.
                                Consulte <Link> Executar Nova Análise</Link>.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <p className="mb-3">
                        Você pode filtrar a coluna da<strong> Empresa MSP Desk</strong> com o ícone de filtro.
                        Se um termo de pesquisa do filtro
                        incluir caracteres curinga (por exemplo, sublinhados e sinais de porcentagem), eles funcionam como caracteres
                        normais se precedidos por uma barra invertida. Para mais detalhes, consulte <Link> Caracteres curinga</Link>.
                        A seleção de filtro nas colunas não persistirá na próxima vez que a página for acessada, pois a
                        tabela retornará à visualização padrão.
                    </p>
                    <p className="mb-3">
                        O botão <strong> Atualizar dados</strong> permite que você preencha a lista de empresas do MSP Desk com base
                        nos dados mais recentes
                        recuperados da API do MSP Desk. Após uma atualização bem-sucedida, os resultados do filtro de Alterações
                        <strong> Não Salvas</strong> são exibidos.
                    </p>
                    <p className="mb-3">
                        O número de resultados exibidos pode ser especificado selecionando o número desejado no controle de paginação.
                        Essa seleção persistirá na próxima vez que a página for acessada.
                    </p>
                    <p className="fs-5 mb-4">Filtros disponíveis na lista de mapeamentos potenciais</p>
                    <p className="mb-3">
                        As categorias de filtro exibem o número de itens que se aplicam a essa categoria, se houver.
                    </p>
                    <img
                        className="mb-3"
                        src={genericImage}
                        alt="Descrição da Imagem"
                        style={{ cursor: 'pointer', width: '30vw', height: '30px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                        onClick={() => handleImageClick(genericImage)}
                    />
                    <Table
                        className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`}
                        striped
                        bordered
                        hover
                    >
                        <thead>
                            <tr>
                                <th>Filtro</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Todos</td>
                                <td>Exibe todos os resultados mapeados e não mapeados na lista.</td>
                            </tr>
                            <tr>
                                <td>Mapeados</td>
                                <td>
                                    Exibe quaisquer resultados mapeados na lista. Um item da lista será categorizado como mapeado se as
                                    alterações não salvas forem salvas com pelo menos um valor inserido na coluna Sites para esse item,
                                    incluindo uma ou mais seleções de sites ou a seleção Não mapear. Para criar mapeamentos, salve ou altere
                                    os valores pré-preenchidos na coluna Sites ou insira valores onde estiverem vazios. Consulte Botões de
                                    ação para aprender como gerenciar alterações não salvas.
                                </td>
                            </tr>
                            <tr>
                                <td>Não mapeados</td>
                                <td>
                                    Exibe quaisquer resultados não mapeados na lista. Um item da lista será categorizado como não mapeado se as
                                    alterações não salvas forem salvas quando nenhum valor tiver sido inserido na coluna Sites para esse item.
                                    Consulte Botões de ação para aprender como gerenciar alterações não salvas.
                                </td>
                            </tr>
                            <tr>
                                <td>Alterações não salvas</td>
                                <td>
                                    Ao alterar o(s) valor(es) na coluna Sites ou ao adicionar um valor ausente, o item da lista é automaticamente
                                    movido para esta aba. Consulte Botões de ação para aprender como gerenciar alterações não salvas.
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="fs-7 mb-4">
                        Botões de ação
                    </p>
                    <img
                        className="mb-3"
                        src={genericImage}
                        alt="Descrição da Imagem"
                        style={{ cursor: 'pointer', width: '30vw', height: '60px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                        onClick={() => handleImageClick(genericImage)}
                    />
                    <p className="mb-2">Os seguintes botões de ação estão disponíveis para o mapeamento:</p>
                    <Table
                        className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`}
                        striped
                        bordered
                        hover
                    >
                        <thead>
                            <tr>
                                <th>Botão de Ação</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Executar Nova Análise</td>
                                <td>
                                    <div className="mb-2">
                                        Às vezes, você pode querer reexecutar sua análise de mapeamento para um ou mais itens.
                                        Esta ferramenta solicitará que o sistema corresponda as empresas do MSP Desk aos sites do
                                        Datto RMM com base no nome. Uma correspondência sugerida pode ou não existir.
                                        Use as caixas de seleção para controlar quais itens passarão pela nova análise.
                                        As caixas de seleção permitem que você selecione uma ou mais linhas. Selecione todas as linhas
                                        exibidas na página selecionando a caixa de seleção no cabeçalho da tabela. Você pode selecionar
                                        caixas de seleção em mais de uma categoria de filtro para aplicar à análise. Ao não selecionar
                                        nenhuma caixa de seleção, todas as empresas serão analisadas.
                                        Ao clicar neste botão, a caixa de diálogo <strong> Executar Nova Análise</strong> exibirá as
                                        seguintes opções:
                                    </div>
                                    <img
                                        className="mb-3"
                                        src={genericImage}
                                        alt="Descrição da Imagem"
                                        style={{ cursor: 'pointer', width: '12vw', height: '65px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                                        onClick={() => handleImageClick(genericImage)}
                                    />
                                    <ul>
                                        <li><strong> Manter mapeamentos existentes e apenas analisar empresas do MSP Desk não mapeadas.</strong></li>
                                        <li><strong> Excluir quaisquer mapeamentos existentes e remapear as empresas selecionadas.</strong></li>
                                    </ul>
                                    <div className="mb-2">
                                        Clique em <strong> Executar Análise</strong> para executar a ação ou <strong> Cancelar</strong> para fechar a caixa de diálogo.
                                        Notificações pop-up indicarão quando a análise começou e foi concluída. Clique em <strong> Ver Resultados</strong> para ser
                                        redirecionado ao cartão de configuração de integração (se você tiver navegado para longe desde que a análise foi iniciada).
                                    </div>
                                    <img
                                        className="mb-3"
                                        src={genericImage}
                                        alt="Descrição da Imagem"
                                        style={{ cursor: 'pointer', width: '15vw', height: '65px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                                        onClick={() => handleImageClick(genericImage)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Descartar Alterações</td>
                                <td>
                                    <div className="mb-3">
                                        Reverte todas as alterações que você fez desde a última gravação, o que limpa os itens em
                                        <strong> Alterações Não Salvas</strong>. Clique em <strong> Confirmar </strong>
                                        para executar a ação ou <strong> Cancelar</strong> para fechar a caixa de diálogo. Este botão não está disponível se não houver alterações não salvas.
                                    </div>

                                    <Card className="mb-2 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                        <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                            <Card.Text>
                                                As seleções de caixas de seleção não impactam esta ação. As alterações serão descartadas para todas as alterações não
                                                salvas, independentemente de quais caixas de seleção estejam selecionadas, se houver.
                                                Consulte <Link> Executar Nova Análise</Link>.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                    <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                        <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                            <Card.Text>
                                                Navegar para fora do cartão de configuração de integração não descartará suas alterações não salvas.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </td>
                            </tr>
                            <tr>
                                <td>Salvar</td>
                                <td>
                                    <div className="mb-3">
                                        Antes de clicar neste botão, clique em <strong> Alterações Não Salvas</strong> para revisar os itens que você alterou desde a
                                        última gravação. Se você deseja preservar todas essas alterações, clique em <strong> Salvar</strong> para enviar sua
                                        configuração de mapeamento
                                        para o banco de dados e iniciar a sincronização. Este botão não está disponível se não houver alterações não salvas.
                                    </div>
                                    <Card className="mb-3 col-xl-12" style={{ borderLeft: '5px solid blue' }}>
                                        <Card.Body style={{ maxHeight: '30vh', paddingTop: '16px', paddingBottom: '16px' }}>
                                            <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
                                            <Card.Text>
                                                As seleções de caixas de seleção não impactam esta ação. As alterações serão salvas para todas as alterações
                                                não salvas, independentemente de quais caixas de seleção estejam selecionadas, se houver.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                {/* Modal com a imagem maior */}
                <Modal
                    show={modalShow}
                    onHide={handleCloseModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onClick={handleCloseModal} // Fecha o modal ao clicar fora
                >
                    <Modal.Body onClick={(e) => e.stopPropagation()}> {/* Impede fechamento ao clicar na imagem */}
                        <img
                            src={selectedImage} // Usa a imagem selecionada
                            alt="Imagem Ampliada"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Modal.Body>
                </Modal>
            </AccordionBody>
        </Accordion.Item>
    )
}