import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Registration from 'components/authentication/simple/Registration';
import SubtleBadge from 'components/common/SubtleBadge';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PricingDefaultCard = ({
  pricing: {
    title,
    subTitle,
    differential,
    price,
    url,
    subscription,
    buttonText,
    isFeatured,
    featureTitle,
    features
  }
}) => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const handleShowRegistrationModal = () => {
    setShowRegistrationModal(true);
  };

  const handleCloseRegistrationModal = () => {
    setShowRegistrationModal(false);
  };

  return (
    <Col
      lg={4}
      className={classNames('border-top border-bottom', {
        'dark__bg-1000 px-4 px-lg-0': isFeatured
      })}
      style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
    >
      <div className="h100">
        <div className="text-center p-4">
          <h3 className="fw-normal my-0">{title}</h3>
          <p className="mt-3">{subTitle}</p>
          {differential ? (
            <p className="mt-3 pe-7">{differential}</p>
          ) : (
            <p className="mt-3 pe-7 opacity-0">.</p>
          )}
          {price ? (
            <h2 className={classNames('fw-medium my-4')}>
              <sup className="fw-normal fs-7 me-1">R$</sup>
              {price}
              <small className="fs-10 text-700"> /{subscription}</small>
            </h2>
          ) : (
            <div className={classNames('fw-medium my-4', 'price-placeholder')}>
              <h2 className='fst-italic fw-medium'>Preço sob consulta</h2>
            </div>
          )}

          {price ? (
            <div className="d-flex justify-content-center">
              <Button
                as={Link}
                to='https://api.whatsapp.com/send?phone=+5515996055486&text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento.'
                target='_blank'
                variant={'primary'}
                className={`mx-4`}
              >
                {buttonText}
              </Button>
            </div>
          ) : (
            <div style={{ marginTop: '17px' }} className="d-flex justify-content-center">
              <Button
                as={Link}
                to='https://api.whatsapp.com/send?phone=+5515996055486&text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento.'
                target='_blank'
                variant={'primary'}
                className="mx-4"
              >
                {buttonText}
              </Button>
            </div>)}

          <Modal
            show={showRegistrationModal}
            centered
            onHide={handleCloseRegistrationModal}
          >
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs-9 fw-normal p-4">
                  <Registration />
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
        </div>
        <hr className="border-bottom-0 m-0" />
        <div className="text-start px-sm-4 py-4">
          <h5 className="fw-medium fs-9">{featureTitle}</h5>
          <ul className="list-unstyled mt-3">
            {features.map(feature => (
              <li className="py-1" key={feature.id}>
                <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                {feature.id === 0 ? <strong>mínimo de </strong> : ''}{' '}
                {feature.title}{' '}
                {feature.tag && (
                  <SubtleBadge pill bg={feature.tag.type}>
                    {feature.tag.label}
                  </SubtleBadge>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  );
};

PricingDefaultCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    differential: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
    buttonText: PropTypes.string,
    isFeatured: PropTypes.bool,
    featureTitle: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        title: PropTypes.string,
        tag: PropTypes.shape({
          label: PropTypes.string,
          type: PropTypes.string
        })
      })
    )
  }).isRequired
};

export default PricingDefaultCard;
